import { useSearchParams as useSearchParamsHook } from 'react-router-dom';

const useSearchParams = () => {
  const [searchParams, onSetSearchParams] = useSearchParamsHook();

  const setSearchParams = (params) => {
    onSetSearchParams((currParams) => {
      Object.entries(params).forEach(([key, value]) => {
        currParams.set(key, value);
      });
      return currParams;
    });
  };

  const removeParams = (params) => {
    onSetSearchParams((currParams) => {
      const newParams = new URLSearchParams(currParams);
      params.forEach((param) => {
        newParams.delete(param);
      });
      return newParams.toString();
    });
  };

  return { searchParams, setSearchParams, removeParams };
};

export default useSearchParams;
