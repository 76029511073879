/* eslint-disable no-restricted-syntax */
export const findDifferentElements = (array1, array2) => {
  const uniqueElements = [];

  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return uniqueElements;
  }

  for (let i = 0; i < array1.length; i++) {
    const element = array1[i];

    if (!array2.includes(element)) {
      uniqueElements.push(element);
    }
  }

  for (let i = 0; i < array2.length; i++) {
    const element = array2[i];

    if (!array1.includes(element) && !uniqueElements.includes(element)) {
      uniqueElements.push(element);
    }
  }

  return uniqueElements;
};

export const findCommonFields = (array) => {
  if (!Array.isArray(array) || array.length === 0) {
    return [];
  }

  const commonFields = {};

  for (const key in array[0]) {
    if (Object.prototype.hasOwnProperty.call(array[0], key) && array[0][key] !== null) {
      commonFields[key] = array[0][key];
    }
  }

  for (let i = 1; i < array.length; i++) {
    for (const key in commonFields) {
      if (Object.prototype.hasOwnProperty.call(array[i], key) && array[i][key] !== commonFields[key]) {
        delete commonFields[key];
      }
    }
  }

  return commonFields;
};

export const getSlicedElements = (array, sliceAmount = 2) => {
  if (!array?.length) {
    return [[], []];
  }
  const sliceEndIndex = sliceAmount < array.length ? sliceAmount : array.length;
  return [array.slice(0, sliceEndIndex), array.slice(sliceEndIndex)];
};
