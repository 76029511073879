import { ModalLayout } from 'ui-component/modals';
import NoteForm from './NoteForm';

const NoteModal = ({ isOpen, onClose, state }) => (
  <ModalLayout isOpen={isOpen} onClose={onClose} maxWidth="md">
    <NoteForm state={state} />
  </ModalLayout>
);

export default NoteModal;
