import { Tooltip } from 'ui-component/components';
import { DetailsView } from 'ui-component/components/_Details';
import { convertStatus } from 'utils/statusConvert';
import { getSlicedElements } from 'utils/array';

const CategoryLabel = ({ category, categoryClassName }) => {
  return (
    <span key={category} className={categoryClassName}>
      {convertStatus(category)}
    </span>
  );
};

const CategoryElement = ({ category, categoryClassName }) => {
  if (typeof category === 'string') {
    return <CategoryLabel category={category} categoryClassName={categoryClassName} />;
  }
  return <CategoryLabel category={category.name} categoryClassName={categoryClassName} />;
};

const CategoriesView = ({ material = {}, statusSize = 'status', sliceLimit = 2, emptyValue = '-' }) => {
  const { materialsCategories, materialCategory } = material;

  const [slicedCategories, restCategories] = getSlicedElements(materialsCategories, sliceLimit);

  const categoryClassName = `${statusSize} status_category`;

  if (!materialsCategories?.length) {
    if (materialCategory) {
      return <CategoryLabel category={materialCategory} categoryClassName={categoryClassName} />;
    }
    return emptyValue || null;
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '3px', flexWrap: 'wrap' }}>
      {slicedCategories.map((category) => (
        <CategoryElement key={category.id || category} category={category} categoryClassName={categoryClassName} />
      ))}
      {materialsCategories.length > sliceLimit ? (
        <Tooltip
          title={
            <div className="tooltip_node">
              <DetailsView
                label="Categories:"
                value={materialsCategories.map((category) => (
                  <CategoryElement
                    key={category.id || category}
                    category={category}
                    categoryClassName={categoryClassName}
                  />
                ))}
                size="sm"
              />
            </div>
          }
          arrow={false}
          isNode
          placement="bottom-start"
          fontSize="small"
        >
          <span className={categoryClassName}>{`+${restCategories.length || 0}`}</span>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default CategoriesView;
