// routing
import Routes from 'routes';
import { ToastContainer, Flip } from 'react-toastify';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import { ConfirmModal, AlertModal } from 'ui-component/modals';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import CircularLoader from 'ui-component/CircularLoader';
import ThemeCustomization from 'themes';

import 'assets/fonts/fonts.css';
import 'react-toastify/dist/ReactToastify.css';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (
  <ThemeCustomization>
    {/* RTL layout */}
    <RTLLayout>
      <Locales>
        <NavigationScroll>
          <AuthProvider>
            <>
              <Routes />
              <Snackbar />
              <CircularLoader />
              <ConfirmModal />
              <AlertModal />
              <ToastContainer position="bottom-left" autoClose={8000} transition={Flip} />
            </>
          </AuthProvider>
        </NavigationScroll>
      </Locales>
    </RTLLayout>
  </ThemeCustomization>
);

export default App;
