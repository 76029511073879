import { CircularProgress } from '@mui/material';

import { percentFormat } from 'utils/intlFormat';

import s from './s.module.scss';

const CircularUploader = ({ value }) => {
  const isUploaded = value > 99;

  return (
    <div className={s.circular_uploader}>
      <CircularProgress variant="determinate" size={200} value={value} />
      <span className={s.status}>{isUploaded ? 'Completed' : percentFormat(value)}</span>
    </div>
  );
};

export default CircularUploader;
