import { CircularProgress } from '@mui/material';

import s from './s.module.scss';

const LoaderBlock = () => (
  <div className={s.block_loader}>
    <CircularProgress style={{ color: '#5e35b1' }} />
  </div>
);

export default LoaderBlock;
