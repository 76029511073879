import { Navigate } from 'react-router-dom';

import { ROUTER_PATHS } from 'config';

// ==============================|| REDIRECT ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '*',
  element: <Navigate replace to={ROUTER_PATHS.LOGIN} />
};

export default AuthenticationRoutes;
