import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sub } from 'date-fns';
import { Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { DatePicker, Input, SelectReact } from 'ui-component/components';
import { checkCountryState } from 'utils/validData';
import { BUSINESS_OWNER_SCHEME } from 'constants/validatorForms';
import { STATES } from 'constants/listsSelect';

import s from './s.module.scss';

const OwnerForm = ({ ownersModalState, onClose, setOwner }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      id: ownersModalState?.editOwner?.id || uuidv4(),
      firstName: ownersModalState?.editOwner?.firstName || '',
      lastName: ownersModalState?.editOwner?.lastName || '',
      dateOfBirth: ownersModalState?.editOwner?.dateOfBirth ? new Date(ownersModalState.editOwner.dateOfBirth) : '',
      socialSecurityNumber: ownersModalState?.editOwner?.socialSecurityNumber || '',
      ownershipPercentage: ownersModalState?.editOwner?.ownershipPercentage || '',
      businessOwnerAddresses: ownersModalState?.editOwner?.businessOwnerAddresses || [
        {
          id: uuidv4(),
          personalAddress: '',
          suite: '',
          city: '',
          state: '',
          usZipCode: ''
        }
      ]
    },
    resolver: yupResolver(BUSINESS_OWNER_SCHEME)
  });

  const { fields } = useFieldArray({
    control,
    name: 'businessOwnerAddresses'
  });

  const isLightValidation = checkCountryState(watch('businessOwnerAddresses').at(0).state, ['Canada']);

  const handleOnSubmit = (data) => {
    if (ownersModalState?.editMode) {
      setOwner((currUser) => ({
        ...currUser,
        businessOwnerRequestDtoList: currUser.businessOwnerRequestDtoList.map((owner) => {
          if (owner.id === data.id) {
            return {
              ...owner,
              firstName: data.firstName,
              lastName: data.lastName,
              dateOfBirth: data.dateOfBirth,
              socialSecurityNumber: data.socialSecurityNumber,
              ownershipPercentage: data.ownershipPercentage,
              businessOwnerAddresses: data.businessOwnerAddresses
            };
          }
          return owner;
        })
      }));
    } else {
      setOwner((currUser) => ({
        ...currUser,
        businessOwnerRequestDtoList: [
          ...currUser.businessOwnerRequestDtoList,
          {
            id: uuidv4(),
            firstName: data.firstName,
            lastName: data.lastName,
            dateOfBirth: data.dateOfBirth,
            socialSecurityNumber: data.socialSecurityNumber,
            ownershipPercentage: data.ownershipPercentage,
            businessOwnerAddresses: [...data.businessOwnerAddresses]
          }
        ]
      }));
    }
    onClose();
  };
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} className={s.owner_form}>
      <p className={s.title}>{`Business owner #${ownersModalState.ownerNumber}`}</p>
      <div className={s.inputs_wrapper}>
        <Controller
          control={control}
          name="firstName"
          render={({ field }) => (
            <Input
              label="Owner First Name"
              placeholder="Enter first name"
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              error={errors?.firstName?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <Input
              label="Owner Last Name"
              placeholder="Enter last name"
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              error={errors?.lastName?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="dateOfBirth"
          render={({ field }) => (
            <DatePicker
              label="Date of Birth"
              placeholder="mm/dd/yyyy"
              popperPlacement="bottom-start"
              date={field.value}
              onChange={(value) => field.onChange(value)}
              maxDate={sub(new Date(), { years: 18, days: 1 })}
              error={errors?.dateOfBirth?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="socialSecurityNumber"
          render={({ field }) => (
            <Input
              label={`Social Security Number ${isLightValidation ? '(optional)' : ''}`}
              placeholder="123-45-6789"
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              error={errors?.socialSecurityNumber?.message}
              inputMask="999-99-9999"
            />
          )}
        />
        <Controller
          control={control}
          name="ownershipPercentage"
          render={({ field }) => (
            <Input
              type="number"
              label="Ownership %"
              placeholder="00%"
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              error={errors?.ownershipPercentage?.message}
            />
          )}
        />
        {fields.map((fieldItem, index) => (
          <div key={fieldItem.id} className={s.owner_address}>
            <Controller
              control={control}
              name={`businessOwnerAddresses.${index}.personalAddress`}
              render={({ field }) => (
                <Input
                  label="Personal Address"
                  placeholder="Enter address"
                  value={field.value}
                  onChange={(_, value) => field.onChange(value)}
                  error={errors?.businessOwnerAddresses?.[index]?.personalAddress?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={`businessOwnerAddresses.${index}.suite`}
              render={({ field }) => (
                <Input
                  label="Apt/Suite (optional)"
                  placeholder="#123"
                  value={field.value}
                  onChange={(_, value) => field.onChange(value)}
                  error={errors?.businessOwnerAddresses?.[index]?.suite?.message}
                  inputMask="#99999"
                />
              )}
            />
            <Controller
              control={control}
              name={`businessOwnerAddresses.${index}.city`}
              render={({ field }) => (
                <Input
                  label="City"
                  placeholder="Enter city"
                  value={field.value}
                  onChange={(_, value) => field.onChange(value)}
                  error={errors?.businessOwnerAddresses?.[index]?.city?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={`businessOwnerAddresses.${index}.state`}
              render={({ field }) => (
                <SelectReact
                  label="State"
                  placeholder="Choose state"
                  options={STATES}
                  value={STATES.find(({ value }) => value === field.value)}
                  onChange={({ value }) => field.onChange(value)}
                  error={errors?.businessOwnerAddresses?.[index]?.state?.message}
                  menuPlacement="top"
                />
              )}
            />
            <Controller
              control={control}
              name={`businessOwnerAddresses.${index}.usZipCode`}
              render={({ field }) => (
                <Input
                  label="Zip Code"
                  placeholder="Enter Zip Code"
                  value={field.value}
                  onChange={(_, value) => field.onChange(value)}
                  error={errors?.businessOwnerAddresses?.[index]?.usZipCode?.message}
                  inputMask={isLightValidation ? null : '99999'}
                />
              )}
            />
          </div>
        ))}
      </div>
      <div className={s.btn_wrapper}>
        <Button type="submit" variant="contained" color="secondary">
          {ownersModalState.editMode ? 'Edit' : 'Add'} Owner
        </Button>
      </div>
    </form>
  );
};

export default OwnerForm;
