/* eslint-disable react/button-has-type */
import clsx from 'clsx';

import s from './s.module.scss';

const COLOR_CN = {
  green: s.green,
  red: s.red,
  purple: s.purple
};

const SIZE_CN = {
  md: s.md,
  sm: s.sm
};

const ButtonSimple = ({
  onClick,
  children,
  leftIcon,
  rightIcon,
  type = 'button',
  color = 'purple',
  size = 'md',
  isDisabled
}) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type={type}
      onClick={handleOnClick}
      className={clsx(s.button, COLOR_CN[color], SIZE_CN[size])}
      disabled={isDisabled}
    >
      {leftIcon || null} {children} {rightIcon || null}
    </button>
  );
};

export default ButtonSimple;
