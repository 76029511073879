import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { useSettingsStore } from 'store/atoms/settingsStore';

const initialState = {
  token: null,
  user: {}
};

const resetAllStores = () => {
  useSettingsStore.getState().reset();
};

export const useAuthStore = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,

        signIn: ({ token, ...userInfo }) => {
          set((store) => ({ token, user: { ...store.user, ...userInfo } }));
        },

        signOut: () => {
          resetAllStores();
          set(initialState);
        }
      }),
      { name: 'p_ap', version: 1.2 }
    ),
    { name: 'p_ap_auth' }
  )
);

// for secure storage

// import secureLocalStorage from 'react-secure-storage';

// const storage = {
//   getItem: async (name) => {
//     return (await secureLocalStorage.getItem(name)) || initialState;
//   },
//   setItem: async (name, value) => {
//     await secureLocalStorage.setItem(name, value);
//   },
//   removeItem: async (name) => {
//     await secureLocalStorage.removeItem(name);
//   }
// };

// { name: 'p_ap', version: 2, storage: createJSONStorage(() => storage) }
