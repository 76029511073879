import FormControlLabel from '@mui/material/FormControlLabel';
import SwitchMUI from '@mui/material/Switch';
import { styled } from '@mui/material';

const StyledFormControlLabel = styled(FormControlLabel)({
  marginLeft: '0px',
  marginRight: '0px',
  '& .MuiFormControlLabel-label': {
    fontSize: '12px',
    fontWeight: '500'
  }
});

const Switch = ({
  value,
  onChange,
  label,
  size = 'medium',
  color = 'secondary',
  isDisabled,
  labelPlacement = 'end'
}) => {
  const handleOnChange = ({ target }) => {
    onChange?.(target.checked);
  };
  return (
    <StyledFormControlLabel
      control={<SwitchMUI size={size} color={color} checked={value} onChange={handleOnChange} />}
      label={label}
      disabled={isDisabled}
      labelPlacement={labelPlacement}
    />
  );
};

export default Switch;
