export const currencyFormat = (value, options) => {
  const { minDigits = 2, maxDigits = 3, currency = 'USD', format = 'en-US' } = options || {};

  const formatter = new Intl.NumberFormat(format, {
    style: 'currency',
    currency,
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits ?? minDigits ?? 0
  });

  return formatter.format(value || 0);
};

export const percentFormat = (value, options) => {
  const { minDigits = 0, maxDigits = 2, format = 'en-US' } = options || {};

  const valueNum = parseFloat(value || 0);

  const formatter = new Intl.NumberFormat(format, {
    style: 'percent',
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits ?? minDigits ?? 0
  });

  return formatter.format(valueNum / 100);
};

export const numberFormat = (value, options) => {
  const { minDigits = 0, maxDigits, format = 'en-US' } = options || {};

  const formatter = new Intl.NumberFormat(format, {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits ?? minDigits ?? 0
  });

  return formatter.format(value || 0);
};

export const numberMetricPrefix = (num, m = 2) => {
  const prefix = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y', '* 10^27', '* 10^30', '* 10^33'];
  let i = 0;
  // eslint-disable-next-line no-cond-assign, no-plusplus, operator-assignment
  while ((num = num / 1000) >= 1) i++;
  return (num * 1000).toFixed(m) + prefix[i];
};
