export const onScrollToTop = () => {
  window?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

export const onScrollToEnd = (ref) => {
  setTimeout(() => {
    ref?.current?.scrollTo({
      top: 100000,
      behavior: 'smooth'
    });
  }, 200);
};

export const onScrollIntoView = (ref, options) => {
  if (!ref) {
    return;
  }
  const { settings, delay = 500 } = options || {};
  setTimeout(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth', ...settings });
  }, delay);
};

export const onOpenNewTab = (url) => {
  if (!url) return;
  window.open(url, '_blank').focus();
};

export const openNewTab = (link) => {
  if (!link) {
    return;
  }

  const a = document.createElement('a');
  a.href = link;
  a.target = '_blank';
  a.rel = 'noreferrer';

  document.body.appendChild(a);

  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
  }, 1000);
};

export const portalDropdownPosition = (wrapperRef, listRef, options) => {
  const { autoPlacement } = options || {};

  if (wrapperRef.current && listRef.current) {
    const targetElement = wrapperRef.current;
    const listElement = listRef.current;

    const targetElementRect = targetElement.getBoundingClientRect();

    const bottomPosition = targetElementRect.bottom;
    const leftPosition = targetElementRect.left;
    const targetElementWidth = targetElementRect.width;

    const { scrollY, scrollX } = window;
    const inputMargin = 10;

    listElement.style.top = `${bottomPosition + scrollY + inputMargin}px`;
    listElement.style.left = `${leftPosition + scrollX}px`;
    listElement.style.width = `${targetElementWidth}px`;

    const listElementRect = listElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (autoPlacement && listElementRect.bottom > windowHeight) {
      const availableHeight = targetElementRect.top;
      const newHeight = Math.min(listElementRect.height, availableHeight);
      listElement.style.height = `${newHeight}px`;
      listElement.style.top = `${targetElementRect.top - newHeight + scrollY + inputMargin}px`;
    } else if (listElementRect.top < 0) {
      const availableHeight = windowHeight - targetElementRect.bottom;
      const newHeight = Math.min(listElementRect.height, availableHeight);
      listElement.style.height = `${newHeight}px`;
      listElement.style.top = `${targetElementRect.bottom + scrollY + inputMargin}px`;
    }
  }
};
