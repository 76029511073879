// project imports
import services from 'utils/mockAdapter';

// user profile list
const users = [
  {
    id: '#1Profile_Elmore',
    avatar: 'friend-1.svg',
    name: 'Elnora',
    role: 'Lead Marketing Facilitator',
    about: 'We need to generate the virtual CSS hard drive!',
    email: 'reid_oconnell4@yahoo.com',
    contact: '506-654-1653',
    location: 'Port Narcos',
    groups: [
      {
        id: '#1Group_Kelli',
        avatar: 'friend-1.svg',
        name: 'Kelli'
      },
      {
        id: '#2Group_Laurence',
        avatar: 'friend-1.svg',
        name: 'Laurence'
      },
      {
        id: '#3Group_Melyssa',
        avatar: 'friend-1.svg',
        name: 'Melyssa'
      },
      {
        id: '#4Group_Montana',
        avatar: 'friend-1.svg',
        name: 'Montana'
      },
      {
        id: '#5Group_Johnathan',
        avatar: 'friend-1.svg',
        name: 'Johnathan'
      }
    ],
    Progress: {
      label: 'Progress',
      variant: 'primary',
      value: 60
    }
  },
  {
    id: '#1Profile_Hipolito',
    avatar: 'friend-1.svg',
    name: 'Hirohito',
    role: 'Investor Creative Liaison',
    about: 'If we synthesize the protocol, we can get to the RSS circuit through.',
    email: 'conner22@hotmail.com',
    contact: '673-157-1670',
    location: 'Handburgh',
    groups: [
      {
        id: '#1Group_Kelli',
        avatar: 'friend-1.svg',
        name: 'Kelli'
      },
      {
        id: '#2Group_Laurence',
        avatar: 'friend-1.svg',
        name: 'Laurence'
      },
      {
        id: '#3Group_Melyssa',
        avatar: 'friend-1.svg',
        name: 'Melyssa'
      },
      {
        id: '#4Group_Montana',
        avatar: 'friend-1.svg',
        name: 'Montana'
      },
      {
        id: '#5Group_Johnathan',
        avatar: 'friend-1.svg',
        name: 'Johnathan'
      }
    ],
    Progress: {
      label: 'Progress',
      variant: 'success',
      value: 95
    }
  },
  {
    id: '#1Profile_Kattie',
    avatar: 'friend-1.svg',
    name: 'Kathie',
    role: 'Human Accountability Strategist',
    about: "connecting the panel won't do anything, we need to reboot the virtual PCI pixel!",
    email: 'dangelo40@company.com',
    contact: '158-348-7483',
    location: 'New jana',
    groups: [
      {
        id: '#1Group_Kelli',
        avatar: 'friend-1.svg',
        name: 'Kelli'
      },
      {
        id: '#2Group_Laurence',
        avatar: 'friend-1.svg',
        name: 'Laurence'
      },
      {
        id: '#3Group_Melyssa',
        avatar: 'friend-1.svg',
        name: 'Melyssa'
      },
      {
        id: '#4Group_Montana',
        avatar: 'friend-1.svg',
        name: 'Montana'
      },
      {
        id: '#5Group_Johnathan',
        avatar: 'friend-1.svg',
        name: 'Johnathan'
      }
    ],
    Progress: {
      label: 'Progress',
      variant: 'error',
      value: 50
    }
  },
  {
    id: '#1Profile_Maudie',
    avatar: 'friend-1.svg',
    name: 'Maudie',
    role: 'Product Solutions Administrator',
    about: 'We need to navigate the virtual SQL system!',
    email: 'al_lueilwitz72@company.com',
    contact: '208-053-8713',
    location: 'Twilahsven',
    groups: [
      {
        id: '#1Group_Kelli',
        avatar: 'friend-1.svg',
        name: 'Kelli'
      },
      {
        id: '#2Group_Laurence',
        avatar: 'friend-1.svg',
        name: 'Laurence'
      },
      {
        id: '#3Group_Melyssa',
        avatar: 'friend-1.svg',
        name: 'Melyssa'
      },
      {
        id: '#4Group_Montana',
        avatar: 'friend-1.svg',
        name: 'Montana'
      },
      {
        id: '#5Group_Johnathan',
        avatar: 'friend-1.svg',
        name: 'Johnathan'
      }
    ],
    Progress: {
      label: 'Progress',
      variant: 'primary',
      value: 80
    }
  },
  {
    id: '#1Profile_Veda',
    avatar: 'friend-1.svg',
    name: 'Veda',
    role: 'Direct Web Administrator',
    about: 'The ADP hard drive is down, transmit the online driver so we can calculate.',
    email: 'roel_wiza@yahoo.com',
    contact: '496-861-7697',
    location: 'New Credrick',
    groups: [
      {
        id: '#1Group_Kelli',
        avatar: 'friend-1.svg',
        name: 'Kelli'
      },
      {
        id: '#2Group_Laurence',
        avatar: 'friend-1.svg',
        name: 'Laurence'
      },
      {
        id: '#3Group_Melyssa',
        avatar: 'friend-1.svg',
        name: 'Melyssa'
      },
      {
        id: '#4Group_Montana',
        avatar: 'friend-1.svg',
        name: 'Montana'
      },
      {
        id: '#5Group_Johnathan',
        avatar: 'friend-1.svg',
        name: 'Johnathan'
      }
    ],
    Progress: {
      label: 'Progress',
      variant: 'primary',
      value: 35
    }
  },
  {
    id: '#1Profile_Damian',
    avatar: 'friend-1.svg',
    name: 'Damian',
    role: 'Central Functionality Specialist',
    about: 'Use the auxiliary XML monitor, then you can copy the open-source microchip!',
    email: 'davon6@yahoo.com',
    contact: '100-605-5130',
    location: 'Murraymouth',
    groups: [
      {
        id: '#1Group_Kelli',
        avatar: 'friend-1.svg',
        name: 'Kelli'
      },
      {
        id: '#2Group_Laurence',
        avatar: 'friend-1.svg',
        name: 'Laurence'
      },
      {
        id: '#3Group_Melyssa',
        avatar: 'friend-1.svg',
        name: 'Melyssa'
      },
      {
        id: '#4Group_Montana',
        avatar: 'friend-1.svg',
        name: 'Montana'
      },
      {
        id: '#5Group_Johnathan',
        avatar: 'friend-1.svg',
        name: 'Johnathan'
      }
    ],
    Progress: {
      label: 'Progress',
      variant: 'primary',
      value: 78
    }
  }
];

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/profile-list/list').reply(200, { users });
