import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ContentCopy from '@mui/icons-material/ContentCopy';

import { Tooltip } from 'ui-component/components';

const CopyClipboard = ({ children, text, copiedText = 'Copied!' }) => {
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => toast.success(copiedText, { autoClose: 1000, hideProgressBar: true, className: 'toast_xs' })}
    >
      <div style={{ cursor: 'pointer', width: 'fit-content' }}>
        <Tooltip
          placement="right"
          title={<ContentCopy fontSize="small" style={{ width: '14px', height: '14px' }} />}
          customStyle={{ boxShadow: 'none', backgroundColor: 'transparent' }}
          isNode
        >
          {children}
        </Tooltip>
      </div>
    </CopyToClipboard>
  );
};

export default CopyClipboard;
