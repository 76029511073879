import { CircularProgress } from '@mui/material';

import s from './s.module.scss';

const LoaderBlock = ({ height = '200px', progressSize = 40 }) => {
  return (
    <div className={s.block_loader} style={{ minHeight: height }}>
      <CircularProgress size={progressSize} style={{ color: '#5e35b1' }} />
    </div>
  );
};

export default LoaderBlock;
