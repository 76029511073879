import { useNavigate, createSearchParams } from 'react-router-dom';

const useNavigation = () => {
  const navigate = useNavigate();

  const historyGoBack = (path, deep = -1) => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(deep, { replace: true });
    } else {
      navigate(path, { replace: true });
    }
  };

  const navigateSearch = (pathname, searchParams) => {
    navigate({ pathname, search: `?${createSearchParams(searchParams)}` });
  };

  return {
    navigate,
    historyGoBack,
    navigateSearch
  };
};

export default useNavigation;
