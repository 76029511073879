import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import AddBox from '@mui/icons-material/AddBox';

import { Input, SelectReact } from 'ui-component/components';
import BUSINESS_API from 'api/business';
import { useLoaderStore } from 'store/atoms/loaderStore';
import { useConfirmModal } from 'store/atoms/confirmStore';
import { isEmptyObject } from 'utils/validData';
import { getMemberFullName } from 'utils/dto';
import { toastError } from 'utils/events';
import { USER_ROLES } from 'constants/enums';
import { USER_ROLES_SELECT } from 'constants/listsSelect';
import { INVITE_USER_SCHEME, BUSINESS_MEMBER_SCHEME } from 'constants/validatorForms';

import s from './s.module.scss';

const InviteForm = ({ state, needOwnerOption, onCloseModal, onSuccessCallback }) => {
  const { updatedUser = {} } = state || {};

  const setLoader = useLoaderStore((store) => store.setLoader);
  const onConfirmModal = useConfirmModal((store) => store.onConfirmModal);

  const isEdiMode = !isEmptyObject(updatedUser);
  const isUserRegistered = getMemberFullName(updatedUser);

  const ROLE_OPTIONS = [
    ...(needOwnerOption ? [{ value: USER_ROLES.OWNER, label: 'Owner' }] : []),
    ...USER_ROLES_SELECT
  ];

  const {
    handleSubmit,
    formState: { errors },
    register,
    control
  } = useForm({
    mode: 'onSubmit',
    defaultValues: isEdiMode
      ? {
          email: updatedUser.email,
          firstName: updatedUser.firstName || null,
          lastName: updatedUser.lastName || null,
          role: updatedUser.role || USER_ROLES.ADMIN
        }
      : { role: USER_ROLES.ADMIN },
    resolver: yupResolver(isEdiMode && isUserRegistered ? BUSINESS_MEMBER_SCHEME : INVITE_USER_SCHEME)
  });

  const handleOnSubmit = (data) => {
    const onSuccess = () => {
      if (onSuccessCallback) {
        onSuccessCallback({ callback: setLoader.off });
      }
      onCloseModal();
    };
    const onCatch = ({ message }) => {
      setLoader.off();
      toastError({ message });
    };

    const isUnregistered = isEdiMode && !isUserRegistered;

    if (isEdiMode && updatedUser.email !== data.email) {
      onConfirmModal({
        question: 'Are you sure you want to change user email?',
        text: isUnregistered
          ? "Since this user hasn't registered yet, an invitation email will be sent to them."
          : null,
        onConfirm: () => {
          BUSINESS_API.updateUser(updatedUser.id, data)
            .then(() => {
              if (isUnregistered) {
                BUSINESS_API.resendInviteEmailTeamUser(updatedUser.id);
              }
              onSuccess();
            })
            .catch(onCatch);
        }
      });
    } else if (isEdiMode) {
      BUSINESS_API.updateUser(updatedUser.id, data).then(onSuccess).catch(onCatch);
    } else {
      BUSINESS_API.inviteUserToBusiness({ ownerID: state.id, roleName: data.role, body: { email: data.email } })
        .then(onSuccess)
        .catch(onCatch);
    }
  };

  return (
    <form>
      <p className={s.title}>
        <AddBox /> {isEdiMode ? 'Edit' : 'Invite'} user
      </p>
      <div className={s.inputs_wrapper}>
        {isEdiMode && isUserRegistered ? (
          <>
            <Input
              register={register('firstName')}
              label="First Name"
              placeholder="Enter first name"
              error={errors.firstName?.message}
            />
            <Input
              register={register('lastName')}
              label="Last Name"
              placeholder="Enter last name"
              error={errors.lastName?.message}
            />
          </>
        ) : null}
        <Input
          type="email"
          register={register('email')}
          label="Email"
          placeholder="Enter user email"
          error={errors.email?.message}
        />
        <Controller
          control={control}
          name="role"
          render={({ field }) => (
            <SelectReact
              options={ROLE_OPTIONS}
              value={ROLE_OPTIONS.find(({ value }) => value === field.value)}
              label="Role"
              placeholder="Select Role"
              onChange={({ value }) => field.onChange(value)}
              error={errors.role?.message}
            />
          )}
        />
      </div>
      <div className={s.btn_wrapper}>
        <Button variant="contained" color="secondary" onClick={handleSubmit(handleOnSubmit)}>
          {isEdiMode ? 'Edit' : 'Invite'} User
        </Button>
      </div>
    </form>
  );
};

export default InviteForm;
