import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { ROUTER_PATHS } from 'config';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
// const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: ROUTER_PATHS.MAIN,
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: ROUTER_PATHS.LOGIN,
      element: <AuthLogin />
    }
    // {
    //   path: '/register',
    //   element: <AuthRegister />
    // },
    // {
    //   path: '/forgot',
    //   element: <AuthForgotPassword />
    // }
  ]
};

export default LoginRoutes;
