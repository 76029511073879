import { useState, useCallback } from 'react';

const useModalState = () => {
  const [modalsState, setModalsState] = useState({});

  const openModal = useCallback(({ modalId, state }) => {
    setModalsState((prevState) => ({
      ...prevState,
      [modalId]: { isOpen: true, state }
    }));
  }, []);

  // const closeModal = useCallback((modalId) => {
  //   setModalsState((prevState) => ({
  //     ...prevState,
  //     [modalId]: { ...prevState[modalId], isOpen: false }
  //   }));
  // }, []);

  const closeAllModals = useCallback(() => {
    setModalsState({});
  }, []);

  // const updateModalState = useCallback((modalId, newState) => {
  //   setModalsState((prevState) => ({
  //     ...prevState,
  //     [modalId]: { ...prevState[modalId], state: newState }
  //   }));
  // }, []);

  const getModalState = (modalId) => {
    const modalInfo = modalsState[modalId];
    return modalInfo ? { ...modalInfo, state: modalInfo.state || {} } : { isOpen: false, state: {} };
  };

  return { modalsState, openModal, closeAllModals, getModalState };
};

export default useModalState;
