import { useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { useSearchParams } from 'hooks';
import { useConfirmModal } from 'store/atoms/confirmStore';
import { DetailsView } from 'ui-component/components/_Details';
import { LoaderBlock } from 'ui-component/components/_Loaders';
import { useLoaderStore } from 'store/atoms/loaderStore';
import STRIPE_API from 'api/stripe';
import OATFI_API from 'api/oatfi';
import { formatDate, DATE_FORMATS } from 'utils/dateFormat';
import { currencyFormat } from 'utils/intlFormat';
import { isEmptyObject } from 'utils/validData';
import { convertStatus } from 'utils/statusConvert';
import { onOpenNewTab } from 'utils/pageHelper';
import { toastError } from 'utils/events';
import { LOAN_OATFI } from 'constants/enums';
import { ROUTER_PARAMS } from 'constants/routerUrl';

import s from './s.module.scss';

const OatfiLoanInfo = ({ onCloseModal }) => {
  const setLoader = useLoaderStore((store) => store.setLoader);
  const onConfirmModal = useConfirmModal((store) => store.onConfirmModal);

  const { searchParams } = useSearchParams();
  const mixedBidId = searchParams.get(ROUTER_PARAMS.MIXED_BID_ID);
  const offerId = searchParams.get(ROUTER_PARAMS.OFFER_ID);

  const [loanInfo, setLoanInfo] = useState({});

  const isActiveLoan = loanInfo.status !== LOAN_OATFI.CLOSED;

  const handleGetLoanInfo = () => {
    if (offerId) {
      OATFI_API.getLoanInfoBid(offerId)
        .then((resp) => setLoanInfo(resp))
        .catch(({ message }) => {
          toastError({ message });
          onCloseModal();
        });
    } else {
      OATFI_API.getLoanInfoMixedBid(mixedBidId)
        .then((resp) => setLoanInfo(resp))
        .catch(({ message }) => {
          toastError({ message });
          onCloseModal();
        });
    }
  };

  const handleGetNetTermsInvoice = () => {
    const handleConfirmModal = (link) => {
      onConfirmModal({
        confirmBtn: 'Invoice',
        question: 'Invoice has been successfully generated',
        onConfirm: () => onOpenNewTab(link)
      });
    };

    setLoader.on();
    if (offerId) {
      STRIPE_API.getNetTermsInvoiceBid(offerId)
        .then((link) => handleConfirmModal(link))
        .catch(toastError)
        .finally(setLoader.off);
    } else {
      STRIPE_API.getNetTermsInvoiceMixedBid(mixedBidId)
        .then((link) => handleConfirmModal(link))
        .catch(toastError)
        .finally(setLoader.off);
    }
  };

  useEffect(() => {
    handleGetLoanInfo();
  }, []);

  if (isEmptyObject(loanInfo)) {
    return <LoaderBlock />;
  }
  return (
    <div>
      <div className={s.details_wrapper}>
        <DetailsView label="Invoice Id:" value={loanInfo.invoiceExternalId} />
        <DetailsView label="Loan Id:" value={loanInfo.loanId} />
        <DetailsView
          label="Draw Date:"
          value={loanInfo.drawDate ? formatDate(loanInfo.drawDate, DATE_FORMATS.MF_D_Y) : null}
        />
        <DetailsView
          label="Due Date:"
          value={loanInfo.dueDate ? formatDate(loanInfo.dueDate, DATE_FORMATS.MF_D_Y) : null}
        />
        <DetailsView
          label="Status:"
          value={<span className={`status status_otf_${loanInfo.status}`}>{convertStatus(loanInfo.status)}</span>}
        />
        <DetailsView
          label="Principal Balance:"
          value={
            <b className="col_persianGreen">
              {currencyFormat((loanInfo.outstandingBalance?.principalBalance || 0) / 100)}
            </b>
          }
        />
        <DetailsView
          label="Fee Balance:"
          value={
            <b className="col_persianGreen">{currencyFormat((loanInfo.outstandingBalance?.feeBalance || 0) / 100)}</b>
          }
        />
        <DetailsView
          label="Total Balance:"
          value={
            <b className="col_persianGreen">{currencyFormat((loanInfo.outstandingBalance?.totalBalance || 0) / 100)}</b>
          }
        />
        <DetailsView
          label="Repaid Amount:"
          value={<b className="col_persianGreen">{currencyFormat((loanInfo.repaidAmount || 0) / 100)}</b>}
        />
      </div>
      {isActiveLoan ? (
        <div className={s.actions}>
          <Button variant="contained" color="secondary" onClick={() => handleGetNetTermsInvoice()}>
            Invoice
          </Button>
        </div>
      ) : (
        <div className={s.actions}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              handleGetNetTermsInvoice({
                mode: 'invoice',
                confirmBtn: 'Invoice',
                question: 'Go to view the invoice'
              })
            }
          >
            Invoice
          </Button>
        </div>
      )}
    </div>
  );
};

export default OatfiLoanInfo;
