import qs from 'query-string';
import { AUTH_INSTANCE } from './api';

const SETTINGS_API = {
  // http://3.234.153.217:8080/swagger-ui/#/user-settings-controller/getIntegrationsByBusinessUsingGET
  getIntegrationByBusiness: (businessId) => AUTH_INSTANCE.get(`/settings/admin/integrations/${businessId}`),
  updateUserName: (userId, data) =>
    AUTH_INSTANCE.put(`/settings/admin/user-name/${userId}?firstName=${data.firstName}&lastName=${data.lastName}`),
  // http://3.234.153.217:8080/swagger-ui/#/authentication-controller/getAllSuperAdminManagersUsingGET
  getAllMembers: () => AUTH_INSTANCE.get('/admin/super-admin-users'),
  // http://3.234.153.217:8080/swagger-ui/#/authentication-controller/addSuperAdminUserUsingPOST
  addMember: (roleName, data) => {
    const query = qs.stringify({ roleName });
    return AUTH_INSTANCE.post(`/admin/super-admin-user?${query}`, data);
  },
  // http://3.234.153.217:8080/swagger-ui/#/authentication-controller/updateSuperAdminUserUsingPUT
  updateMember: (userId, data) => AUTH_INSTANCE.put(`/admin/super-admin-user/${userId}`, data),
  // https://demo-api.getply.com/swagger-ui/#/lambda-invoker-controller/invokeResetDemoInstanceLambdaUsingPOST
  resetDemoInstance: () => AUTH_INSTANCE.post('/lambda/reset-demo-instance')
};

export default SETTINGS_API;
