import { useRoutes } from 'react-router-dom';

// routes
// import MainRoutes from './MainRoutes';
import DashboardRoutes from './DashboardRoutes';
import LoginRoutes from './LoginRoutes';
import RedirectRoutes from './RedirectRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // return useRoutes([DashboardRoutes, LoginRoutes, MainRoutes, RedirectRoutes]);
  return useRoutes([DashboardRoutes, LoginRoutes, RedirectRoutes]);
}
