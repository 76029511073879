export const isEmptyValue = (value) => value == null || value.length === 0;

export const isEmptyObject = (obj) => {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export const isNullable = (variable) => {
  return variable == null;
};

export const findMessage = (jsonObj) => {
  let message = null;
  const findMessageHelper = (obj) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (key === 'message') {
        message = obj[key];
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        findMessageHelper(obj[key]);
      }
    }
  };

  findMessageHelper(jsonObj);
  return message;
};

export const hasCompletedRequest = (requestsArray) => {
  if (!requestsArray?.length) {
    return false;
  }
  for (let i = 0; i < requestsArray.length; i++) {
    if (requestsArray[i].materialsBuyRequestRfqStatus === 'COMPLETED') {
      return true;
    }
  }
  return false;
};

export const isUrl = (str) => {
  if (!str) {
    return false;
  }
  const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:[\w-]+\.)+[a-z]{2,}(?::\d{1,5})?(?:\/[\w\-./?#&%!=]*)?$/;
  return urlRegex.test(str);
};

export const isValidUrl = (string) => {
  if (!string) {
    return false;
  }
  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
};

export const checkCountryState = (state, countryNames) => {
  if (typeof state !== 'string' || !Array.isArray(countryNames)) {
    return false;
  }

  const formattedStateValue = state.toLowerCase();

  return countryNames.some((countryName) => {
    const formattedCountryName = countryName.toLowerCase();
    return formattedStateValue.includes(formattedCountryName);
  });
};
