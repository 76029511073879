import { isUrl } from 'utils/validData';

export const capitalizeFirstLetter = (string = '') =>
  string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();

export const trimFirstLetter = (string = '') => {
  if (!string) {
    return '';
  }
  return string.trim().charAt(0);
};

export const onSearchByFields = (fields, target = '') => {
  if (!Array.isArray(fields) || typeof target !== 'string') {
    return false;
  }
  return fields.join('').toLowerCase().includes(target.trim().toLowerCase());
};

export const pluralize = (count, noun, options) => {
  const { suffix = 's', isShowCount = true } = options || {};
  const countView = isShowCount ? count || 0 : '';

  return `${countView} ${noun || ''}${count > 1 ? suffix : ''}`.trim();
};

export const truncate = (str, n) => (str?.length > n ? `${str.slice(0, n - 1)}...` : str);

export const getInitials = (name) => {
  if (typeof name !== 'string') {
    return '';
  }

  const words = name.trim().split(' ');
  if (words.length === 1) {
    return words[0].charAt(0).toUpperCase() + (words[0].charAt(1) || '');
  }
  if (words.length >= 2) {
    const firstNameInitial = words[0].charAt(0).toUpperCase();
    const lastNameInitial = words[words.length - 1].charAt(0).toUpperCase();
    return firstNameInitial + lastNameInitial;
  }

  return '';
};

export const splitFileUrl = (url) => {
  const result = { name: '', extension: '' };

  if (isUrl(url)) {
    const parts = url.split('/');
    const fileNameAndFormat = parts[parts.length - 1].split('?')[0];
    const fileNameAndFormatParts = fileNameAndFormat.split('.');

    result.name = fileNameAndFormatParts[0];
    result.extension = fileNameAndFormatParts[fileNameAndFormatParts.length - 1];
  } else {
    const fileNameAndFormatParts = url.split('.');

    result.name = fileNameAndFormatParts.slice(0, -1).join('.');
    result.extension = fileNameAndFormatParts.slice(-1)[0];
  }

  return result;
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }

  let cleaned = phoneNumber.replace(/[^\d]/g, '');

  if (cleaned.startsWith('1') && !cleaned.startsWith('+')) {
    cleaned = `+${cleaned}`;
  }

  if (!cleaned.startsWith('+')) {
    cleaned = `+1${cleaned}`;
  }

  return cleaned;
};
