/* eslint-disable no-useless-escape */
const REGEX = {
  US_ZIP_CODE: /\b\d{5}\b/,
  // NUMBER_TWO_DIGITS_AFTER_DOT: /(?:^| )\d+(\.\d{1,2})?(?=$| )/,
  URL: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  URL_OR_EMPTY_STRING: /^$|^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  SEQUENTIALLY_SPACES: /^([A-Z0-9])([A-Z0-9_@,.'`_/()!#&+-]| (?! $)){0,255}$/i,
  // NOT_NUMBERS: /^[^0-9]+$/,
  NOT_ALLOW_SPACE: /^\S+$/,
  TEXT_SIMPLE: /^\D([A-Za-z\-`'\s]+)$/g,
  CYRYLIC_VALIDATION: /^[^\u0400-\u04FF]*$/,
  PASSWORD_COMPLEXITY: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]).{8,}$/
};

export default REGEX;
