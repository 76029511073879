import { useState, useEffect, forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import clsx from 'clsx';

import 'react-datepicker/dist/react-datepicker.css';
import s from './s.module.scss';

const sizeCN = {
  sm: s.sm,
  xs: s.xs
};

const DatePicker = ({
  placeholder = 'Choose a date',
  error,
  date,
  onChange,
  label,
  isDisabled = false,
  dateFormat = 'MM-dd-yyyy',
  minDate,
  maxDate,
  size,
  popperPlacement = 'top-start'
}) => {
  const [innerDate, setInnerDate] = useState();

  const handleChangeDate = (selectedDate) => {
    setInnerDate(selectedDate);
    if (onChange) {
      onChange(selectedDate);
    }
  };

  useEffect(() => {
    setInnerDate(date);
  }, [date]);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className={clsx(s.custom_input, !value && s.empty)} ref={ref} onClick={onClick}>
      {value || placeholder}
    </button>
  ));

  return (
    <div className={clsx(s.date_picker_wrapper, sizeCN[size])}>
      {label ? <p className={s.label}>{label}</p> : null}
      <ReactDatePicker
        dateFormat={dateFormat}
        customInput={<CustomInput />}
        selected={innerDate}
        onChange={handleChangeDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={isDisabled}
        minDate={minDate}
        maxDate={maxDate}
        popperPlacement={popperPlacement}
        popperModifiers={{
          flip: {
            behavior: ['bottom'] // don't allow it to flip to be above
          },
          preventOverflow: {
            enabled: false // tell it not to try to stay within the view (could cause issues with accessibility)
          },
          hide: {
            enabled: false // turn off since needs to be shifted to align with input
          }
        }}
      />
      {error ? <span className={s.error}>{error}</span> : null}
    </div>
  );
};

export default DatePicker;
