/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { useIsMount } from 'hooks';

import s from './style.module.scss';

const FileUpload = ({
  viewUpload,
  hint,
  error,
  accept,
  maxSize = 50000000,
  setFiles,
  multiple = true,
  isRequired = false
}) => {
  const isMount = useIsMount();

  const [addedFiles, setAddedFiles] = useState([]);
  const [innerError, setInnerError] = useState('');

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      setInnerError('');
      setAddedFiles([...acceptedFiles]);
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            toast.error(`Choose a file smaller than ${(maxSize / 1000000).toFixed(2)} MB`);
          }

          if (err.code === 'file-invalid-type') {
            toast.error('Select a file of the allowed format');
          }
        });
      });
    },
    [maxSize]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept,
    maxSize,
    multiple,
    noClick: true,
    noKeyboard: true
  });

  const handleOpenFileModal = () => {
    open();
  };

  // const removeFile = (file) => () => {
  //   const newFiles = [...addedFiles];
  //   newFiles.splice(newFiles.indexOf(file), 1);
  //   setAddedFiles(newFiles);
  // };

  useEffect(() => {
    if (isMount) {
      return;
    }
    setFiles(addedFiles);
  }, [addedFiles]);

  useEffect(() => {
    setInnerError(error);
  }, [error]);

  return (
    <div className={s.drop_zone}>
      <div className={s.drop_zone_btn} {...getRootProps()}>
        <input required={isRequired} {...getInputProps()} />
        <div onClick={handleOpenFileModal} role="button" tabIndex={0}>
          {viewUpload}
        </div>
      </div>
      {innerError ? <div className={clsx(s.hint, s.error)}>{innerError}</div> : null}
      {hint ? <div className={s.hint}>{hint}</div> : null}
    </div>
  );
};

export default FileUpload;
