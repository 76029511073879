import { useForm, Controller } from 'react-hook-form';
import { Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input } from 'ui-component/components';
import { NOTE_SCHEME } from 'constants/validatorForms';

import s from './s.module.scss';

const NoteForm = ({ state, onCloseModal }) => {
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      note: state.note || ''
    },
    resolver: yupResolver(NOTE_SCHEME)
  });

  const handleOnSubmit = (data) => {
    if (state.onAddNoteCallback) {
      state.onAddNoteCallback(data.note);
      onCloseModal();
    }
  };

  return (
    <form className={s.modal} onSubmit={handleSubmit(handleOnSubmit)}>
      <p className={s.title}>{state.content?.title}</p>
      <div className={s.inputs_wrapper}>
        <Controller
          control={control}
          name="note"
          render={({ field }) => (
            <Input
              placeholder="start writing message..."
              isMultiline
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              error={errors?.note?.message}
            />
          )}
        />
      </div>
      <div className={s.actions}>
        <Button type="submit" variant="contained" color="secondary">
          {state.content?.btnSubmit}
        </Button>
      </div>
    </form>
  );
};

export default NoteForm;
