export const ROUTER_PATH = {
  MAIN: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  DASHBOARD_PROJECTS: '/projects',
  DASHBOARD_SUPPLIERS: '/suppliers',
  DASHBOARD_RFQs: '/material-requests',
  DASHBOARD_MATERIALS: '/material-request-items',
  DASHBOARD_OFFERS: '/offers',
  DASHBOARD_MIXED_BIDS: '/mixed-bids',
  DASHBOARD_BUSINESS: '/business',
  DASHBOARD_ORDERS: '/orders',
  DASHBOARD_STORAGES: '/stock',
  DASHBOARD_SETTINGS: '/settings',
  MEMBERS: '/members',
  GENERAL_SETTINGS: '/general-settings',
  GRADER: '/grader',
  // EXTERNAL
  LOGIN_REDIRECT_APP: '/l-a-tkn'
};

export const ROUTER_PARAMS = {
  RFQ_ID: 'requestId',
  ID: 'id',
  BUSINESS_ID: 'businessId',
  PROJECT_ID: 'projectId',
  OFFER_ID: 'offerId',
  MIXED_BID_ID: 'mixedBidId',
  LOCATION: 'location',
  REFRESH: 'refresh',
  TAB: 'tab',
  STATUS: 'status',
  NET_TERMS: 'netTerms'
};
