import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import { ROUTER_PATHS } from 'config';
import { useAuthStore } from 'store/atoms/authStore';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const token = useAuthStore((store) => store.token);

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(ROUTER_PATHS.LOGIN, { replace: true });
    }
  }, [token]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
