import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WorkIcon from '@mui/icons-material/Work';

export const MESSAGES = {
  ERROR: 'Oops, something went wrong',
  DATA_LOADING: 'Problem loading data',
  DATA_SAVED: 'The data has been successfully saved',
  FILES_UPLOAD_EMPTY: 'Please upload file(s)',
  NOT_READY: 'Will be ready soon'
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  MEMBER: 'MEMBER',
  OWNER: 'OWNER',
  ALL: 'ALL',
  TECH: 'TECH'
};

export const ADMIN_ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  SUPER_MANAGER: 'SUPER_MANAGER',
  ALL: 'ALL'
};

export const OFFER_STATUSES = {
  PENDING: 'PENDING',
  WON: 'WON',
  PAID: 'PAID',
  LOST: 'LOST',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT'
};

export const OFFER_TYPE = {
  MIXED_BID: 'MIXED_BID',
  PRICE: 'PRICE',
  LEAD_TIME: 'LEAD_TIME',
  CUSTOM: 'CUSTOM'
};

export const PROJECT_STATUS = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  COMPLETED: 'COMPLETED',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED'
};

export const PRIORY_STATUS = {
  URGENT: 'URGENT',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  CONTACT_ME: 'CONTACT_ME'
};

export const RFQ_STATUS = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  COMPLETED: 'COMPLETED',
  LOGISTICS_FINALIZED: 'LOGISTICS_FINALIZED',
  DELIVERED: 'DELIVERED',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
  ARCHIVED: 'ARCHIVED'
};

export const RFQ_FINANCING_TYPE = {
  NET_TERMS: 'NET_TERMS',
  PAY_DIRECTLY: 'PAY_DIRECTLY'
};

export const LOAN_OATFI = {
  OUTSTANDING: 'OUTSTANDING',
  DEFAULT: 'DEFAULT',
  CLOSED: 'CLOSED',
  LATE: 'LATE'
};

export const SERVICE_INTEGRATION = {
  NOT_CONNECTED: 'NOT_CONNECTED',
  CONNECTION_EXPIRED: 'CONNECTION_EXPIRED',
  CONNECTED: 'CONNECTED',
  SERVICE_TITAN: 'SERVICE_TITAN',
  HOUSECALL_PRO: 'HOUSECALL_PRO',
  JOBBER: 'JOBBER',
  SERVICE_TITAN_RESET: 'SERVICE_TITAN_RESET',
  HOUSECALL_PRO_RESET: 'HOUSECALL_PRO_RESET',
  JOBBER_RESET: 'JOBBER_RESET'
};

export const LOCATION_TYPE = {
  WAREHOUSE: 'WAREHOUSE',
  TRUCK: 'TRUCK',
  JOB: 'JOB',
  PICK_LIST: 'PICK_LIST'
};

export const LOCATION_ICON_BY_TYPE = {
  [LOCATION_TYPE.TRUCK]: <LocalShippingIcon />,
  [LOCATION_TYPE.WAREHOUSE]: <WarehouseIcon />,
  [LOCATION_TYPE.JOB]: <WorkIcon />
};

export const REG_STEPS = {
  FINISH: 100
};

export const BID_TIMELINE_TO_DAYS = {
  ONE_DAY: 1,
  TWO_DAYS: 2,
  THREE_TO_FIVE_DAYS: 5,
  ONE_WEEK: 7,
  TWO_WEEKS: 14,
  THREE_WEEKS: 21,
  ONE_MONTH: 30
};

export const QUERY_PARAMS = {
  PAGE_SIZE_ALL: 2 ** 30
};

export const MATERIAL_LEAD_TIME_VALUE = {
  IN_STOCK: 'In Stock',
  ONE_DAY: '1 Day',
  TWO_DAYS: ' 2 Days',
  THREE_TO_FIVE_DAYS: '3-5 Days',
  ONE_WEEK: '1 Week',
  TWO_WEEKS: '2 Weeks',
  THREE_WEEKS: '3 Weeks',
  FOUR_WEEKS: '4 Weeks',
  FIVE_WEEKS: '5 Weeks',
  SIX_WEEKS: '6 Weeks',
  SEVEN_WEEKS: '7 Weeks',
  EIGHT_WEEKS: '8 Weeks',
  NINE_WEEKS: '9 Weeks',
  TEN_WEEKS: '10 Weeks',
  ELEVEN_WEEKS: '11 Weeks',
  TWELVE_WEEKS: '12 Weeks',
  THIRTEEN_WEEKS: '13 Weeks',
  FOURTEEN_WEEKS: '14 Weeks',
  FIFTEEN_WEEKS: '15 Weeks',
  SIXTEEN_WEEKS: '16 Weeks',
  SEVENTEEN_WEEKS: '17 Weeks',
  EIGHTEEN_WEEKS: '18 Weeks',
  NINETEEN_WEEKS: '19 Weeks',
  TWENTY_WEEKS: '20 Weeks',
  NOT_AVAILABLE: 'Not available'
};

export const SUPPLIERS_SORTING_TYPE = {
  LATEST_USED: 'LATEST_USED',
  LATEST_USED_FOR_BUSINESS: 'LATEST_USED_FOR_BUSINESS',
  MOST_POPULAR: 'MOST_POPULAR'
};

export const GRADER_RFQ_STATUS = {
  NEW: 'NEW',
  PROCESSED: 'PROCESSED',
  COMPLETED: 'COMPLETED'
};

export const NET_TERMS_STATUS = {
  PROCESSING: 'PROCESSING',
  FUNDED: 'FUNDED',
  REPAID: 'REPAID'
};

export const TAXES = {
  PLY_TAX: 0.07
};

export const RFQ_TYPE = {
  PROJECT_REQUEST: 'PROJECT_REQUEST',
  SINGLE_REQUEST: 'SINGLE_REQUEST',
  REPLENISHMENT_REQUEST: 'REPLENISHMENT_REQUEST'
};

export const SUBSCRIPTION_DATA = {
  PLAN_LENGTH_MONTHLY: 'MONTHLY',
  PLAN_LENGTH_YEARLY: 'YEARLY',
  PLAN_TYPE_LEGACY: 'LEGACY',
  PLAN_TYPE_LOCATIONS: 'LOCATIONS',
  PLAN_TYPE_CUSTOM: 'CUSTOM'
};

export const ENV_TYPE = {
  DEV: 'DEV',
  PROD: 'PROD',
  DEMO: 'DEMO'
};
