import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import { ROUTER_PATHS } from 'config';
import { useAuthStore } from 'store/atoms/authStore';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
  const token = useAuthStore((store) => store.token);

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate(ROUTER_PATHS.DASHBOARD_BUSINESS, { replace: true });
    }
  }, [token]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
