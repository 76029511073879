import clsx from 'clsx';

import s from './s.module.scss';

const sizeVariant = {
  md: s.md,
  sm: s.sm
};

const NoRowsOverlay = ({ message = 'You have no data', height = '100%', size = 'md' }) => (
  <div className={clsx(s.no_rows, sizeVariant[size])} style={{ height }}>
    <p>{message}</p>
  </div>
);

export default NoRowsOverlay;
