import { cloneElement } from 'react';
import { styled } from '@mui/material/styles';
import { Drawer as DrawerMUI, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';

import s from './s.module.scss';

const SIZE = {
  sm: {
    maxWidth: '600px',
    width: '100%'
  },
  xs: {
    maxWidth: '350px',
    width: '100%'
  }
};

const StyledDrawer = styled(DrawerMUI)(({ size }) => ({
  '& .MuiPaper-root': {
    ...SIZE[size]
  }
}));

const Drawer = ({
  isOpen,
  onClose,
  anchor = 'right',
  size = 'sm',
  children,
  isCloseClickOutside,
  ...childrenProps
}) => {
  const handleClose = (_, reason) => {
    if (!isCloseClickOutside && reason && reason === 'backdropClick') {
      return;
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <StyledDrawer open={isOpen} onClose={handleClose} anchor={anchor} size={size}>
      <div className={s.drawer_wrapper}>
        {cloneElement(children, { onCloseModal: handleClose, ...childrenProps })}
        <IconButton size="small" className={s.close_btn} onClick={handleClose}>
          <Close />
        </IconButton>
      </div>
    </StyledDrawer>
  );
};

export default Drawer;
