import { useEffect } from 'react';

const useClickOutside = (refs, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < refs.length; i++) {
        const el = refs[i]?.current;

        if (!el || el.contains(event.target)) {
          return;
        }
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [refs, handler]);
};

export default useClickOutside;
