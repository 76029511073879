import { cloneElement } from 'react';
import { Dialog, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Close from '@mui/icons-material/Close';
import clsx from 'clsx';

import s from './s.module.scss';

const StyledDialog = styled(Dialog)(({ padding, fullScreen }) => ({
  '& .MuiPaper-root': {
    padding,
    width: fullScreen ? '100%' : 'calc(100% - 30px)',
    maxHeight: fullScreen ? '100%' : 'calc(100% - 30px)',
    margin: fullScreen ? '0px' : '15px',

    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px'
    },

    '&::-webkit-scrollbar-track': {
      background: '#ffffff',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)'
    },

    '&::-webkit-scrollbar-thumb': {
      border: '2px solid #5e35b1',
      backgroundColor: '#5e35b1'
    }
  }
}));

const ModalLayout = ({
  isOpen,
  onClose,
  children,
  maxWidth = 'lg',
  fullScreen,
  padding = '10px 0px',
  ...childrenProps
}) => {
  const handleClose = (_, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      transitionDuration={{ enter: 200, exit: 0 }}
      padding={padding}
      disableRestoreFocus
    >
      <div className={clsx(s.modal, fullScreen && s.modal_fullScreen)}>
        {cloneElement(children, { onCloseModal: onClose, ...childrenProps })}
      </div>
      <IconButton size="medium" className={clsx(s.close, fullScreen && s.close_fullScreen)} onClick={onClose}>
        <Close />
      </IconButton>
    </StyledDialog>
  );
};

export default ModalLayout;
