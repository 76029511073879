import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { ROUTER_PATH } from 'constants/routerUrl';

const DashboardProjects = Loadable(lazy(() => import('views/dashboard/Projects')));
const DashboardSuppliers = Loadable(lazy(() => import('views/dashboard/Suppliers')));
const DashboardRFQs = Loadable(lazy(() => import('views/dashboard/RFQs')));
const DashboardMaterialRequestItems = Loadable(lazy(() => import('views/dashboard/MaterialRequestItems')));
const DashboardOffers = Loadable(lazy(() => import('views/dashboard/Offers')));
const DashboardMixedBids = Loadable(lazy(() => import('views/dashboard/MixedBids')));
const DashboardBusiness = Loadable(lazy(() => import('views/dashboard/Business')));
const DashboardOrders = Loadable(lazy(() => import('views/dashboard/Orders')));
const DashboardStorages = Loadable(lazy(() => import('views/dashboard/Storages')));
const DashboardSettings = Loadable(lazy(() => import('views/dashboard/Settings')));
const DashboardGrader = Loadable(lazy(() => import('views/dashboard/Grader')));

const DashboardRoutes = {
  path: ROUTER_PATH.MAIN,
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: ROUTER_PATH.MAIN,
      element: <DashboardBusiness />
    },
    {
      path: ROUTER_PATH.DASHBOARD_BUSINESS,
      element: <DashboardBusiness />
    },
    {
      path: ROUTER_PATH.DASHBOARD_PROJECTS,
      element: <DashboardProjects />
    },
    {
      path: ROUTER_PATH.DASHBOARD_SUPPLIERS,
      element: <DashboardSuppliers />
    },
    {
      path: ROUTER_PATH.DASHBOARD_RFQs,
      element: <DashboardRFQs />
    },
    {
      path: ROUTER_PATH.DASHBOARD_MATERIALS,
      element: <DashboardMaterialRequestItems />
    },
    {
      path: ROUTER_PATH.DASHBOARD_OFFERS,
      element: <DashboardOffers />
    },
    {
      path: ROUTER_PATH.DASHBOARD_MIXED_BIDS,
      element: <DashboardMixedBids />
    },
    {
      path: ROUTER_PATH.DASHBOARD_ORDERS,
      element: <DashboardOrders />
    },
    {
      path: ROUTER_PATH.DASHBOARD_STORAGES,
      element: <DashboardStorages />
    },
    {
      path: `${ROUTER_PATH.DASHBOARD_SETTINGS}/*`,
      element: <DashboardSettings />
    },
    // GRADER
    {
      path: ROUTER_PATH.GRADER,
      element: <DashboardGrader />
    }
  ]
};

export default DashboardRoutes;
