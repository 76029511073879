import s from './s.module.scss';

const LabeledData = ({ label, children, empty = '-' }) => {
  return (
    <div>
      <p className={s.label}>{label}</p>
      <p className={s.data}>{children || empty}</p>
    </div>
  );
};

export default LabeledData;
