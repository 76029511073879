import { ModalLayout } from 'ui-component/modals';
import AdditionalInvoiceItems from './AdditionalInvoiceItems';

const AdditionalInvoiceItemsModal = ({ state, onSuccessCallback, ...modalProps }) => {
  return (
    <ModalLayout {...modalProps} maxWidth="sm">
      <AdditionalInvoiceItems state={state} onSuccessCallback={onSuccessCallback} />
    </ModalLayout>
  );
};

export default AdditionalInvoiceItemsModal;
