import qs from 'query-string';
import { AUTH_INSTANCE } from './api';

const BUSINESS_API = {
  // http://3.234.153.217:8080/swagger-ui/#/business-controller/getAllUsingGET
  getAllBusiness: () => AUTH_INSTANCE.get('/businesses/admin'),
  getBusinessById: (businessId) => AUTH_INSTANCE.get(`/businesses/admin/${businessId}`),
  // http://3.234.153.217:8080/swagger-ui/#/business-controller/updateUsingPUT
  updateBusinessDetails: (businessId, data) => AUTH_INSTANCE.put(`/businesses/admin/${businessId}`, data),
  updateBusinessOwners: (businessId, data) => AUTH_INSTANCE.put(`/businesses/admin/owners/${businessId}`, data),
  // http://3.234.153.217:8080/swagger-ui/#/authentication-controller/registerAdminUserUsingPOST
  createUser: (data) => AUTH_INSTANCE.post('/admin/register', data),
  // http://3.234.153.217:8080/swagger-ui/#/user-settings-controller/updateUserInfoByAdminUsingPUT
  updateUser: (userId, data) => AUTH_INSTANCE.put(`/settings/admin/users/${userId}`, data),
  getBusinesses: (queryParams) => {
    const { pageNumber = 0, pageSize = 10, searchQuery = '' } = queryParams || {};
    return AUTH_INSTANCE.get(
      `/businesses/admin/search?pageNumber=${pageNumber}&pageSize=${pageSize}${`&${searchQuery}`}`
    );
  },
  getBusinessByNewBusiness: (params) => {
    const query = qs.stringify({ pageSize: 10, pageNumber: 0, ...params });
    return AUTH_INSTANCE.get(`/businesses/admin/new?${query}`);
  },
  getBusinessByNewUsers: (params) => {
    const query = qs.stringify({ pageSize: 10, ...params });
    return AUTH_INSTANCE.get(`/businesses/admin/users?${query}`);
  },
  getBusinessByActive: (params) => {
    const query = qs.stringify({ pageSize: 10, ...params });
    return AUTH_INSTANCE.get(`/businesses/admin/active?${query}`);
  },
  changeCommissionTax: (businessId, commission) => {
    return AUTH_INSTANCE.put(`/businesses/admin/commission/${businessId}?commission=${commission}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/business-controller/deleteBusinessUsingDELETE
  deleteBusiness: (businessId) => AUTH_INSTANCE.delete(`/businesses/admin/${businessId}`),
  // http://3.234.153.217:8080/swagger-ui/#/user-settings-controller/removeUserByIdAdminUsingDELETE
  deleteUser: (userId, params) => {
    const query = qs.stringify(params);
    return AUTH_INSTANCE.delete(`/settings/admin/users/${userId}?${query}`);
  },
  getBusinessesStatistics: () => AUTH_INSTANCE.get('/businesses/admin/statistics'),
  changeTeamUserActivationStatus: ({ userId, activationStatus, ownerId }) => {
    const query = qs.stringify({ activationStatus, ownerId });
    return AUTH_INSTANCE.put(`/admin/user/activation-status/${userId}?${query}`);
  },
  changeTeamUserRole: ({ userId, roleName }) => {
    const query = qs.stringify({ roleName });
    return AUTH_INSTANCE.put(`/users/roles/${userId}?${query}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/user-settings-controller/resendEmailUsingPUT
  resendInviteEmailTeamUser: (userId) => AUTH_INSTANCE.put(`/settings/invited-user/resend-email/${userId}`),
  inviteUserToBusiness: ({ ownerID, roleName, body }) => {
    const query = qs.stringify({ ownerID, roleName });
    return AUTH_INSTANCE.post(`/admin/create-account/invited-user?${query}`, body);
  },
  //
  enableDailySummaryEmail: (businessId) => {
    const query = qs.stringify({ businessId });
    return AUTH_INSTANCE.patch(`/businesses/admin/daily-summary?${query}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/authentication-controller/getUserTokenUsingGET
  getUserToken: (userId) => AUTH_INSTANCE.get(`/admin/user/${userId}/token`),

  // Subscriptions -------------------------------------------------------------------------------
  // http://3.234.153.217:8080/swagger-ui/#/business-controller/getBusinessTariffFromAdminUsingGET
  getBusinessTariff: (businessId) => AUTH_INSTANCE.get(`/businesses/admin/${businessId}/tariff`)
  // ---------------------------------------------------------------------------------------------
};

export default BUSINESS_API;
