import { toast } from 'react-toastify';

import { MESSAGES } from 'constants/enums';

export const preventDefault = (e, callback) => {
  if (!e) {
    return;
  }

  e.stopPropagation();
  e.preventDefault();

  if (callback) {
    callback();
  }
};

export const getErrorMessage = (message, defaultMessage) => {
  if (Array.isArray(message) && message.length) {
    return message[0];
  }
  if (message && typeof message === 'string') {
    return message;
  }
  return defaultMessage || MESSAGES.ERROR;
};

export const toastError = (params) => {
  const { message } = params || {};
  const errorMessage = getErrorMessage(message);
  toast.error(errorMessage);
};
