import { ModalLayout } from 'ui-component/modals';
import OatfiLoanInfo from './OatfiLoanInfo';

const OatfiLoanInfoModal = ({ ...modalProps }) => {
  return (
    <ModalLayout {...modalProps} maxWidth="sm">
      <OatfiLoanInfo />
    </ModalLayout>
  );
};

export default OatfiLoanInfoModal;
