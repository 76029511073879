import { Checkbox as CheckboxMUI, FormGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFormControlLabel = styled(FormControlLabel)(({ alignItemsForm }) => ({
  width: 'fit-content',
  color: '#000000',
  '& .MuiCheckbox-root': {
    padding: '0px 9px'
  },
  '& .MuiFormControlLabel-label': {
    fontFamily: 'inherit!important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px'
  },
  '& .MuiFormControlLabel-root': {
    alignItems: alignItemsForm
  }
}));

const StyledFormGroup = styled(FormGroup)(({ alignItemsForm, color }) => ({
  width: 'fit-content',
  '& .MuiFormControlLabel-root': {
    alignItems: alignItemsForm
  },
  '& .Mui-checked': {
    color: color === 'secondary' ? '#5e35b1!important' : '#355F51!important'
  },
  '& .Mui-disabled': {
    color: '#B2B2B2!important'
  }
}));

const Checkbox = ({
  label,
  onChange,
  isChecked,
  isRequired,
  disableRipple = true,
  color = 'secondary',
  alignItemsForm = 'center',
  isDisabled
}) => {
  const handleCheckBox = ({ target: { checked } }) => {
    onChange?.(checked);
  };

  return (
    <StyledFormGroup alignItemsForm={alignItemsForm} color={color}>
      <StyledFormControlLabel
        alignItemsForm={alignItemsForm}
        control={
          <CheckboxMUI
            required={isRequired}
            color={color}
            onChange={handleCheckBox}
            checked={isChecked}
            disableRipple={disableRipple}
            disabled={isDisabled}
          />
        }
        label={label || ''}
      />
    </StyledFormGroup>
  );
};

export default Checkbox;
