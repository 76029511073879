import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import { Input, SelectReact } from 'ui-component/components';
import { MATERIAL_CATEGORY_SELECT } from 'constants/listsSelect';
import { MATERIAL_MORE_INFO_SCHEME } from 'constants/validatorForms';

import s from './s.module.scss';

const MaterialMoreInfo = ({ state, onSuccessCallback, onCloseModal }) => {
  const isInitMaterial = state.initMaterialId;

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      note: state.note || '',
      manufacturer: state.manufacturer || '',
      materialCategory: state.materialCategory || ''
    },
    resolver: yupResolver(MATERIAL_MORE_INFO_SCHEME)
  });

  const handleOnSubmit = (data) => {
    if (onSuccessCallback) {
      onSuccessCallback(
        {
          material: {
            manufacturer: data.manufacturer || null,
            materialCategory: data.materialCategory || null
          },
          materialDetails: {
            note: data.note || ''
          }
        },
        state.id
      );
    }
    onCloseModal();
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className={s.fields}>
        <Controller
          control={control}
          name="manufacturer"
          render={({ field }) => (
            <Input
              label="Manufacturer"
              placeholder="Enter manufacturer"
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              isDisabled={isInitMaterial}
              tooltip={isInitMaterial ? 'You can change this information only for new materials' : ''}
              error={errors?.manufacturer?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="materialCategory"
          render={({ field }) => (
            <SelectReact
              label="Category"
              placeholder="Select material category"
              options={MATERIAL_CATEGORY_SELECT}
              value={MATERIAL_CATEGORY_SELECT.find(({ value }) => value === field.value)}
              tooltip={isInitMaterial ? 'You can change this information only for new materials' : ''}
              isDisabled={isInitMaterial}
              onChange={(option) => field.onChange(option?.value || '')}
              isClearable
            />
          )}
        />
        <Controller
          control={control}
          name="note"
          render={({ field }) => (
            <Input
              label="Note"
              placeholder="start writing message..."
              isMultiline
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              tooltip="Add a note about this product/material"
              error={errors?.note?.message}
            />
          )}
        />
      </div>
      <div className={s.actions}>
        <Button type="submit" variant="contained" color="secondary">
          Save
        </Button>
      </div>
    </form>
  );
};

export default MaterialMoreInfo;
