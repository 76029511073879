import PaginationMUI from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';

const StyledPagination = styled(PaginationMUI)(() => ({
  '& .Mui-selected': {
    color: '#ffffff!important'
  }
}));

const Pagination = ({ page, onChange, count, size = 'medium', boundaryCount = 1 }) => {
  const handleOnChange = (_, value) => {
    if (onChange) {
      onChange(value - 1);
    }
  };

  return (
    <StyledPagination
      size={size}
      count={count}
      boundaryCount={boundaryCount}
      page={page + 1}
      onChange={handleOnChange}
    />
  );
};

export default Pagination;
