import { Backdrop, CircularProgress } from '@mui/material/';

import { useLoaderStore } from 'store/atoms/loaderStore';

const CircularLoader = () => {
  const isLoading = useLoaderStore((store) => store.isLoading);

  return (
    <Backdrop open={isLoading} style={{ zIndex: '9999', backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
};

export default CircularLoader;
