export const convertStatus = (string = '') => {
  if (!string) {
    return '';
  }
  return string.replaceAll('_', ' ').toLowerCase();
};

export const convertAdminRole = (str) => {
  if (!str) {
    return '';
  }
  const prefix = 'SUPER_';
  if (str.startsWith(prefix)) {
    str = str.substring(prefix.length);
  }
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
