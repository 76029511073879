import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import SETTINGS_API from 'api/settings';
import { toastError } from 'utils/events';

const INITIAL_STATE = { members: null };

export const useSettingsStore = create(
  devtools(
    (set) => ({
      ...INITIAL_STATE,

      fetchMembers: async () => {
        try {
          const members = await SETTINGS_API.getAllMembers();
          set({ members });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('fetchMembers: ~ error:', error);
          toastError({ message: error?.message });
        }
      },

      reset: () => set(INITIAL_STATE)
    }),
    { name: 'p_ap_settings' }
  )
);
