import { formatDistanceToNow, format, formatISO } from 'date-fns';

export const DATE_FORMATS = {
  FULL_M_D_Y_H: 'MMM dd, yyyy, HH:mm',
  FULL_M_D_Y_H_NUMBER: 'MM.dd.yyyy HH:mm',
  FULL_M_D_Y_NUMBER: 'MM.dd.yyyy',
  M_D_Y: 'MMM dd, yyyy',
  MF_D_Y: 'MMMM dd, yyyy'
};

export const formatDateToNow = (date) => {
  const targetDate = date ? new Date(date) : new Date();
  return formatDistanceToNow(
    new Date(targetDate.setMinutes(targetDate.getMinutes() - targetDate.getTimezoneOffset())),
    { addSuffix: true }
  );
};

export const formatDate = (date, dateFormat = DATE_FORMATS.FULL_M_D_Y_H) => {
  const targetDate = date ? new Date(date) : new Date();
  return format(new Date(targetDate.setMinutes(targetDate.getMinutes() - targetDate.getTimezoneOffset())), dateFormat);
};

export const formatToISO = (date) => {
  const targetDate = date ? new Date(date) : new Date();
  return formatISO(targetDate, { representation: 'date' });
};
