import { useRef } from 'react';
import { Menu, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import MoreVert from '@mui/icons-material/MoreVert';

import { useBoolean } from 'hooks';
import { preventDefault } from 'utils/events';

import s from './s.module.scss';

const MenuStyled = styled((props) => (
  <Menu
    elevation={0}
    disableScrollLock
    keepMounted
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 40, horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 160 }}
    disableRestoreFocus
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
    '& .MuiMenu-list': {
      minWidth: '160px',
      maxWidth: '160px',
      padding: '0px',

      '& li': {
        padding: '8px 10px',
        fontSize: '14px',
        maxWidth: '160px',
        whiteSpace: 'normal'
      }
    }
  }
}));

const MenuControl = ({ children, isRounded, fontSize = 'medium' }) => {
  const anchorRef = useRef(null);
  const [isOpenMenu, setIsOpenMenu] = useBoolean(false);

  const handleOpenMenu = (e) => {
    preventDefault(e, setIsOpenMenu.on);
  };

  const handleCloseMenu = (e) => {
    preventDefault(e, setIsOpenMenu.off);
  };

  return (
    <>
      <IconButton ref={anchorRef} size="small" onClick={handleOpenMenu} className={clsx(isRounded && s.rounded)}>
        <MoreVert fontSize={fontSize} />
      </IconButton>
      <MenuStyled anchorEl={anchorRef.current} open={isOpenMenu} onClose={setIsOpenMenu.off} onBlur={handleCloseMenu}>
        {children}
      </MenuStyled>
    </>
  );
};

export default MenuControl;
