/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import InputMask from 'react-input-mask';
import clsx from 'clsx';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';

import { useBoolean } from 'hooks';
import { Tooltip } from 'ui-component/components';

import s from './s.module.scss';

const sizeCN = {
  sm: s.sm,
  xs: s.xs
};

const Input = ({
  value,
  onChange,
  objKey = '',
  type = 'text',
  placeholder,
  label,
  isDisabled,
  isRequired,
  isMultiline,
  heightTextArea = 100,
  error,
  size = 'md',
  tooltip,
  onClickInput,
  refInput,
  inputMask,
  isAutoComplete = true,
  endAdornment,
  startAdornment,
  register,
  formatChars = {}
}) => {
  const [isShowPassword, setIsShowPassword] = useBoolean(false);

  const handleChange = ({ target }) => {
    if (onChange) {
      onChange(objKey, target.value);
    }
  };

  const handleClickInput = () => {
    if (onClickInput) {
      onClickInput();
    }
  };

  const onWheel = ({ target }) => target.blur();

  return isMultiline ? (
    <div className={clsx(s.input_wrapper, sizeCN[size])}>
      {label ? (
        <p className={s.label}>
          {label}
          {tooltip ? <Tooltip title={tooltip} fontSize="small" /> : null}
        </p>
      ) : null}
      <div className={s.input_block}>
        <textarea
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          className={s.input}
          onWheel={onWheel}
          disabled={isDisabled}
          required={isRequired}
          style={{ height: `${heightTextArea}px` }}
          autoComplete={isAutoComplete ? 'on' : 'new-password'}
          {...register}
        />
      </div>
      {error ? <span className={s.error}>{error}</span> : null}
    </div>
  ) : (
    <div className={clsx(s.input_wrapper, sizeCN[size])}>
      {label ? (
        <p className={s.label}>
          {label}
          {tooltip ? <Tooltip fontSize="small" title={tooltip} /> : null}
        </p>
      ) : null}
      <div className={s.input_block} onClick={handleClickInput}>
        {startAdornment ? <span className={s.start_adornment}>{startAdornment}</span> : null}
        {inputMask ? (
          <InputMask
            value={value}
            onWheel={onWheel}
            onChange={handleChange}
            mask={inputMask}
            disabled={isDisabled}
            maskChar=""
            autoComplete={isAutoComplete ? 'on' : 'new-password'}
            required={isRequired}
            formatChars={{ 9: '[0-9]', a: '[A-Za-z]', '*': '[A-Za-z0-9]', ...formatChars }}
          >
            {() => (
              <input
                type={type === 'password' && isShowPassword ? 'text' : type}
                placeholder={placeholder}
                className={clsx(
                  s.input,
                  (type === 'password' || endAdornment) && s.input_btn_wrapper,
                  startAdornment && s.start_adornment_input,
                  isDisabled && s.disabled
                )}
                ref={refInput}
              />
            )}
          </InputMask>
        ) : (
          <input
            type={type === 'password' && isShowPassword ? 'text' : type}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            className={clsx(
              s.input,
              (type === 'password' || endAdornment) && s.input_btn_wrapper,
              startAdornment && s.start_adornment_input
            )}
            onWheel={onWheel}
            disabled={isDisabled}
            required={isRequired}
            ref={refInput}
            autoComplete={isAutoComplete ? 'on' : 'new-password'}
            {...register}
          />
        )}
        {type === 'password' ? (
          <button className={s.input_btn} type="button" onClick={setIsShowPassword.toggle}>
            {isShowPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
          </button>
        ) : null}
        {endAdornment ? <div className={s.end_adornment}>{endAdornment}</div> : null}
      </div>
      {error ? <span className={s.error}>{error}</span> : null}
    </div>
  );
};

export default Input;
