import qs from 'query-string';
import { AUTH_INSTANCE } from './api';

const MATERIAL_REQUESTS_API = {
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/getWithSpecificationsUsingGET
  getRFQsSearch: (filter = {}) => {
    const { pageSize = 10, pageNumber = 0, ...rest } = filter;
    const params = { pageSize, pageNumber, ...rest };
    const query = qs.stringify(params, { arrayFormat: 'comma', skipEmptyString: true, skipNull: true });

    return AUTH_INSTANCE.get(`/materials-financings/admin/materials-buy-requests/search?${query}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/getMaterialsBuyRequestAdminUsingGET
  getRFQbyId: (id) => AUTH_INSTANCE.get(`/materials-financings/admin/materials-buy-requests/${id}`),

  createMaterialRequest: (data) => AUTH_INSTANCE.post('/materials-financings/admin/create-material-request', data),
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/deleteMaterialRequestUsingDELETE
  deleteMaterialRequest: (materialRequestId) =>
    AUTH_INSTANCE.delete(`/materials-financings/admin/materials-buy-requests/${materialRequestId}`),

  updateMaterialRequest: (materialRequestId, data) =>
    AUTH_INSTANCE.put(`/materials-financings/admin/update-material-request/${materialRequestId}`, data),

  createMaterialItem: (data) => AUTH_INSTANCE.post('/materials-financings/admin/create-material-item', data),

  // http://3.234.153.217:8080/swagger-ui/#/materials-controller/updateMaterialByAdminUsingPUT
  updateMaterial: (materialId, data) => AUTH_INSTANCE.put(`/materials-financings/admin/materials/${materialId}`, data),

  deleteMaterialItem: (materialId) => AUTH_INSTANCE.delete(`/materials-financings/admin/delete-material/${materialId}`),

  getProjectsByBusinessId: (businessId) =>
    AUTH_INSTANCE.get(`/materials-financings/admin/business-projects/${businessId}`),
  // OLD http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/testPdfUsingPOST
  // generatePdfForSupplier: (materialBuyRequestId, supplierId, files) =>
  //   AUTH_INSTANCE.post(
  //     `/materials-financings/material-buy-requests/pdf/${materialBuyRequestId}/${supplierId}`,
  //     { fileNames: files },
  //     { responseType: 'blob' }
  //   ),
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/downloadZipRfqSummaryUsingPOST
  downloadZipRFQSummary: (rfqId, data) =>
    AUTH_INSTANCE.post(`/materials-financings/material-buy-requests/pdf/${rfqId}`, data, { responseType: 'blob' }),
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/updateMaterialBuyRequestUsingPUT
  updateRFQ: (materialRequestId, { file, fulfillmentInfo, materialsBuyRequest }) => {
    const formData = new FormData();

    formData.set(
      'materialsBuyRequest',
      new Blob([JSON.stringify(materialsBuyRequest)], {
        type: 'application/json'
      })
    );

    formData.set(
      'fulfillmentInfo',
      new Blob([JSON.stringify(fulfillmentInfo)], {
        type: 'application/json'
      })
    );

    file?.forEach((file) => formData.append('file', file));

    return AUTH_INSTANCE.put(`/materials-financings/admin/materials-buy-requests/${materialRequestId}`, formData);
  },

  getRFQsStatistics: () => AUTH_INSTANCE.get('/materials-financings/admin/materials-buy-requests/statistics'),
  addUsersToRfq: (materialBuyRequestId, assignedUserIds) => {
    const query = qs.stringify({ assignedUserIds });
    return AUTH_INSTANCE.put(`/materials-financings/material-buy-request/users/${materialBuyRequestId}?${query}`);
  },
  getBusinessByRFQId: (materialsBuyRequestId) =>
    AUTH_INSTANCE.get(`/businesses/admin/by-materials-buy-request/${materialsBuyRequestId}`),
  sendRFQSummary: (materialBuyRequestId) => AUTH_INSTANCE.post(`/email/send-rfq-summary/${materialBuyRequestId}`),
  attachFilesToRFQ: (materialsBuyRequestId, files) => {
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    return AUTH_INSTANCE.put(
      `/materials-financings/materials-buy-requests/${materialsBuyRequestId}/attach-files`,
      formData
    );
  },
  sendShippingTrackingEmail: (data) => AUTH_INSTANCE.post('/materials-financings/admin/shipping-tracking', data),
  getShippingTrackingRFQ: (id) =>
    AUTH_INSTANCE.get(`/materials-financings/admin/shipping-tracking/materials-buy-request/${id}`),
  // http://3.234.153.217:8080/swagger-ui/#/email-controller/sendRFQPdfToSuppliersFromAdminUsingPOST
  sendRFQSummaryEmail: (materialBuyRequestId, data) =>
    AUTH_INSTANCE.post(`/email/admin/material-buy-request/pdf/${materialBuyRequestId}`, data),
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/cloneMaterialsBuyRequestWithAllDataUsingPOST
  cloneRFQ: (rfqId) => AUTH_INSTANCE.post(`/materials-financings/materials-buy-requests/${rfqId}/clone`),
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/reclaimMaterialsBuyRequestUsingPATCH
  reclaimRFQ: (id) => AUTH_INSTANCE.patch(`/materials-financings/admin/materials-buy-requests/${id}/reclaim`),
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/getRFQNotesForMaterialsBuyRequestUsingGET
  getRFQNotes: (materialsBuyRequestId, params) => {
    const query = qs.stringify({ pageSize: 10, pageNumber: 0, ...params });
    return AUTH_INSTANCE.get(
      `/materials-financings/materials-buy-requests/${materialsBuyRequestId}/rfq-notes?${query}`
    );
  },
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/addRFQNoteUsingPOST
  addRFQNote: (materialsBuyRequestId, data) =>
    AUTH_INSTANCE.post(`/materials-financings/materials-buy-requests/${materialsBuyRequestId}/rfq-notes`, data),
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/assignBuyerToRFQUsingPATCH
  assignBuyerToRFQ: (materialRequestId, buyerId) =>
    AUTH_INSTANCE.patch(
      `/materials-financings/admin/materials-buy-requests/${materialRequestId}/assign-buyer/${buyerId}`
    ),
  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/deleteAttachedFilesFromMaterialsBuyRequestUsingDELETE
  deleteFileFromRFQ: (rfqId, fileName) => {
    const encodedFileName = encodeURIComponent(fileName);
    return AUTH_INSTANCE.delete(`/materials-financings/admin/materials-buy-requests/${rfqId}/file/${encodedFileName}`);
  },

  // RFQ Transparency ------------
  //  http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/getByMaterialsBuyRequestSuppliersForReminderUsingGET
  getCommunicatedSuppliersByBuyer: (rfqId) =>
    AUTH_INSTANCE.get(`/materials-financings/materials-buy-requests/${rfqId}/reminder`),

  // http://3.234.153.217:8080/swagger-ui/#/materials-buy-request-controller/updateSupplierContactedListUsingPATCH
  updateCommunicatedSuppliersList: (rfqId, data) =>
    AUTH_INSTANCE.patch(`/materials-financings/admin/materials-buy-requests/${rfqId}/reminder`, data)
  // ------------------------------------
};

export default MATERIAL_REQUESTS_API;
