import { Button } from '@mui/material';

import { ModalLayout } from 'ui-component/modals';
import { useAlertStore } from 'store/atoms/alertStore';

import { isEmptyObject } from 'utils/validData';

import s from './s.module.scss';

const AlertModal = () => {
  // title, text, content, onClose, closeBnt
  const { state, offAlertModal } = useAlertStore((store) => ({
    state: store.state,
    offAlertModal: store.offAlertModal
  }));
  const { title, text, closeBnt, content, onClose } = state;

  const handleClickAction = () => {
    if (onClose) {
      onClose();
    }
    offAlertModal();
  };

  return (
    <ModalLayout isOpen={!isEmptyObject(state)} onClose={handleClickAction} fullWidth maxWidth="md">
      <div className={s.modal}>
        {title ? <p className={s.title}>{title}</p> : null}
        {text ? <p className={s.text}>{text}</p> : null}
        {content || null}
        <div className={s.btns_wrapper}>
          <Button variant="contained" color="secondary" onClick={handleClickAction}>
            {closeBnt || 'Close'}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default AlertModal;
