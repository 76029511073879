import Settings from '@mui/icons-material/Settings';

import s from './s.module.scss';

const SettingsButton = ({ icon, children, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button type="button" className={s.manage_btn} onClick={handleClick}>
      {icon || <Settings fontSize="small" />} {children}
    </button>
  );
};

export default SettingsButton;
