import ReactSelect from 'react-select';
import clsx from 'clsx';

import { Tooltip } from 'ui-component/components';

import s from './s.module.scss';

const sizeCN = {
  react_select_sm: s.sm,
  react_select_xs: s.xs
};

const SelectReact = ({
  value,
  onChange,
  options,
  label,
  error,
  placeholder = 'Select...',
  variant = 'react_select',
  menuPlacement = 'auto',
  isSearchable = false,
  isClearable = false,
  isDisabled,
  colorStyles = {},
  selectRef,
  tooltip,
  isMulti,
  keyValue
}) => {
  const handleOnChange = (option) => {
    onChange?.(option);
  };

  return (
    <div className={clsx(s.select_wrapper, sizeCN[variant])}>
      {label ? (
        <span className={s.label}>
          {label}
          {tooltip ? <Tooltip title={tooltip} fontSize="small" /> : null}
        </span>
      ) : null}
      <ReactSelect
        key={keyValue}
        value={value}
        onChange={handleOnChange}
        options={options}
        // menuIsOpen
        isOptionDisabled={(option) => option?.disabled}
        classNamePrefix={variant}
        placeholder={placeholder}
        menuPlacement={menuPlacement}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), ...colorStyles }}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        inputProps={{ autoComplete: 'new-password' }}
        ref={selectRef}
      />
      {error ? <span className={s.error}>{error}</span> : null}
    </div>
  );
};

export default SelectReact;
