import currency from 'currency.js';
import isEmpty from 'lodash/isEmpty';

import { USER_ROLES, OFFER_STATUSES, RFQ_STATUS } from 'constants/enums';

export const getBusinessRootUser = (users) => {
  if (!users?.length) {
    return {};
  }

  return users.find(({ role }) => role === USER_ROLES.OWNER) || {};
};

export const getOfferTotalAmount = (offer, digitsLimit = 3) => {
  if (isEmpty(offer)) {
    return 0;
  }

  const materialsAmount = offer.offerPrice || 0;
  const commission = offer.commission || 0;
  const additionalInvoiceItemsAmount = offer.additionalInvoiceItems?.length
    ? offer.additionalInvoiceItems.reduce(
        (acc, { cost }) => currency(acc + (cost || 0), { precision: digitsLimit }).value,
        0
      )
    : 0;

  const totalAmount = currency(materialsAmount + commission + additionalInvoiceItemsAmount, {
    precision: digitsLimit
  }).value;

  return totalAmount;
};

export const findAcceptedOffer = (rfq, statuses = [OFFER_STATUSES.WON, OFFER_STATUSES.PAID]) => {
  const { materialsOffers, mixedBids } = rfq;

  const result = [...materialsOffers, ...mixedBids].find(({ offerStatus, mixedBidStatus }) =>
    statuses.includes(offerStatus || mixedBidStatus)
  );

  if (!result) {
    return {};
  }

  return result;
};

export const isAcceptedRFQStatusUp = (status) => {
  if (!status) {
    return null;
  }
  return [RFQ_STATUS.ACCEPTED, RFQ_STATUS.COMPLETED, RFQ_STATUS.LOGISTICS_FINALIZED, RFQ_STATUS.DELIVERED].includes(
    status
  );
};

export const checkStorageQuantity = (material) => {
  if (!material) {
    return null;
  }

  const { quantity, criticalAmount, lowAmount } = material;

  if (quantity <= criticalAmount) {
    return 'critical';
  }

  if (quantity <= lowAmount) {
    return 'low';
  }

  return null;
};

export const getActiveFiltersCount = (fields, excludeFields = []) => {
  let activeFiltersCount = 0;

  if (!fields) {
    return activeFiltersCount;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key in fields) {
    if (Object.prototype.hasOwnProperty.call(fields, key) && !excludeFields.includes(key)) {
      if (fields[key]) {
        // eslint-disable-next-line no-plusplus
        activeFiltersCount++;
      }
    }
  }

  return activeFiltersCount;
};

export const getMemberFullName = (member, options) => {
  if (!member) {
    return '';
  }
  const { isInsteadEmail = false } = options || {};

  const firstName = member.firstName ?? '';
  const lastName = member.lastName ?? '';

  const fullName = `${firstName} ${lastName}`.trim();
  if (!fullName && isInsteadEmail) {
    return member.email;
  }
  return fullName;
};
