import { ModalLayout } from 'ui-component/modals';
import InviteForm from './InviteForm';

const InviteUserToBusinessModal = ({ state, needOwnerOption, onSuccessCallback, ...modalProps }) => {
  return (
    <ModalLayout {...modalProps} maxWidth="md">
      <InviteForm state={state} needOwnerOption={needOwnerOption} onSuccessCallback={onSuccessCallback} />
    </ModalLayout>
  );
};

export default InviteUserToBusinessModal;
