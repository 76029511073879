import { AvatarGroup as AvatarGroupMUI, Avatar } from '@mui/material';

import { Tooltip } from 'ui-component/components';
import { getInitials } from 'utils/stringFormat';
import { avatarGradient } from 'utils/color';
import { isUrl } from 'utils/validData';

import s from './s.module.scss';

const AvatarGroup = ({ max = 10, users, isTooltip = true }) => {
  const renderAvatar = ({ name, avatarUrl }, i) => {
    const avatarProps = {
      sx: {
        fontSize: 10,
        lineHeight: 14,
        width: 23,
        height: 23,
        background: avatarGradient(name),
        color: '#ffffff'
      },
      children: getInitials(name),
      src: isUrl(avatarUrl) ? avatarUrl : ''
    };

    return isTooltip ? (
      <Tooltip key={`${name}${i}`} isIconTooltip={false} title={name}>
        <Avatar {...avatarProps} />
      </Tooltip>
    ) : (
      <Avatar key={`${name}${i}`} isIconTooltip={false} {...avatarProps} />
    );
  };

  return (
    <div className={s.avatar_wrapper}>
      <AvatarGroupMUI
        max={max}
        componentsProps={{
          additionalAvatar: {
            sx: { width: 23, height: 23, fontSize: 10, bgcolor: '#000000', color: '#B0EFB0' }
          }
        }}
      >
        {users?.map((user, i) => renderAvatar(user, i))}
      </AvatarGroupMUI>
    </div>
  );
};

export default AvatarGroup;

// const AvatarGroup = ({ max = 10, users, isTooltip = true }) => {
//   return (
//     <div className={s.avatar_wrapper}>
//       <AvatarGroupMUI
//         max={max}
//         componentsProps={{
//           additionalAvatar: {
//             sx: { width: 23, height: 23, fontSize: 10, bgcolor: '#000000', color: '#B0EFB0' }
//           }
//         }}
//       >
//         {users?.map(({ name, avatarUrl }, i) =>
//           isTooltip ? (
//             <Tooltip key={`${name}${i}`} isIconTooltip={false} title={name}>
//               <Avatar
//                 sx={{
//                   fontSize: 10,
//                   lineHeight: 14,
//                   width: 23,
//                   height: 23
//                 }}
//                 style={{ background: avatarGradient(name), color: '#ffffff' }}
//                 // eslint-disable-next-line react/no-children-prop
//                 children={getInitials(name)}
//                 src={isUrl(avatarUrl) ? avatarUrl : ''}
//               />
//             </Tooltip>
//           ) : (
//             <Avatar
//               key={`${name}${i}`}
//               isIconTooltip={false}
//               sx={{
//                 fontSize: 10,
//                 lineHeight: 14,
//                 width: 23,
//                 height: 23
//               }}
//               style={{ background: avatarGradient(name), color: '#ffffff' }}
//               // eslint-disable-next-line react/no-children-prop
//               children={getInitials(name)}
//               src={isUrl(avatarUrl) ? avatarUrl : ''}
//             />
//           )
//         )}
//       </AvatarGroupMUI>
//     </div>
//   );
// };

// export default AvatarGroup;
