import * as yup from 'yup';

import { checkCountryState } from 'utils/validData';
import REGEX from 'constants/regex';
import { OFFER_STATUSES, SERVICE_INTEGRATION } from 'constants/enums';

const MESSAGES = {
  REQUIRED: 'This field is required',
  EMAIl_VALID: 'Please enter a valid email',
  PHONE_VALID: 'Enter a valid phone number',
  URL_VALID: 'Enter a valid url',
  US_ZIP_CODE_VALID: 'Enter a valid zip code',
  FILE: 'Please upload the files',
  PASSWORD_MIN_LENGTH: 'Password must be longer than 8 characters',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  PASSWORD_COMPLEXITY:
    'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character',
  AGE_LIMIT: 'You must be 16 years old',
  DATE_VALID: 'Please enter a valid date',
  ZIP_VALID: 'Please enter a valid ZIP code',
  AMOUNT_MIN: 'Please enter an amount greater than 0',
  NUMBER_TYPE: 'This field must be a number',
  NUMBER_MIN: 'Please enter an number greater than 0',
  CITY_CORRECT: 'Enter the correct city',
  PERCENTAGE_VALID: 'Enter a valid percentage',
  VALUE_VALID: 'Enter a valid value',
  MAX_CHARACTERS: 'The maximum number of characters is 250',
  LIMIT_CHARACTERS: 'The character limit has been exceeded',
  CYRYLIC_NOT_ALLOWED: 'Cyrillic characters are not allowed.'
};

export const SUPPLIER_CRUD_SCHEME = yup.object().shape({
  businessName: yup.string().required(MESSAGES.REQUIRED).trim(),
  contactName: yup.string().required(MESSAGES.REQUIRED).trim(),
  businessEmail: yup
    .string()
    .required(MESSAGES.REQUIRED)
    .email(MESSAGES.EMAIl_VALID)
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .trim(),
  city: yup.string().trim(),
  address: yup.string().trim(),
  phoneNumber: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .min(8, MESSAGES.PHONE_VALID),
  state: yup.string(),
  usZipCode: yup.string().when('state', (value, schema) => {
    const isLightValidation = checkCountryState(value, ['Canada']);
    if (isLightValidation) {
      return schema;
    }
    return schema.matches(REGEX.US_ZIP_CODE, { message: MESSAGES.US_ZIP_CODE_VALID, excludeEmptyString: true });
  }),
  note: yup.string().max(250, MESSAGES.MAX_CHARACTERS).nullable().trim(),
  tags: yup.array()
});

export const OFFER_CRUD_SCHEME = yup.object().shape({
  offerPrice: yup.string().typeError(MESSAGES.REQUIRED),
  supplierContact: yup.object().typeError(MESSAGES.REQUIRED).required(MESSAGES.REQUIRED),
  supplierNote: yup.string().matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED).nullable().trim(),
  financingType: yup
    .string()
    .nullable()
    .when('offerStatus', {
      is: (value) => [OFFER_STATUSES.PAID].includes(value),
      then: yup.string().required(MESSAGES.REQUIRED).nullable()
    }),
  materialsDetails: yup.array().of(
    yup.object().shape({
      cost: yup.number().when('available', {
        is: false,
        then: yup.number().nullable(true).typeError(MESSAGES.NUMBER_TYPE).min(0.01, MESSAGES.NUMBER_MIN),
        otherwise: yup
          .number()
          .required(MESSAGES.REQUIRED)
          .typeError(MESSAGES.NUMBER_TYPE)
          .min(0.01, MESSAGES.NUMBER_MIN)
      }),
      measurement: yup.string().required(MESSAGES.REQUIRED),
      leadTime: yup.string().when(['available', 'swappedMaterialDetails'], {
        is: (available, swappedMaterialDetails) => !available || swappedMaterialDetails,
        then: yup.string().nullable(),
        otherwise: yup.string().typeError(MESSAGES.REQUIRED).required(MESSAGES.REQUIRED)
      }),
      quantity: yup.number().when('available', {
        is: false,
        then: yup.number().nullable(),
        otherwise: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(1, MESSAGES.NUMBER_MIN)
      }),
      note: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .max(250, MESSAGES.MAX_CHARACTERS)
        .nullable()
        .trim(),
      material: yup.object().shape({
        name: yup
          .string()
          .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
          .max(250, MESSAGES.MAX_CHARACTERS)
          .required(MESSAGES.REQUIRED)
          .trim(),
        brand: yup
          .string()
          .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
          .max(250, MESSAGES.MAX_CHARACTERS)
          .nullable(true)
          .trim()
      }),
      swappedMaterialDetails: yup
        .object()
        .nullable(true)
        .shape({
          material: yup.object().shape({
            name: yup
              .string()
              .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
              .max(250, MESSAGES.MAX_CHARACTERS)
              .required('Please select a material from the available materials list')
              .trim()
          }),
          leadTime: yup.string().typeError(MESSAGES.REQUIRED).required(MESSAGES.REQUIRED),
          cost: yup.number().nullable(true).typeError(MESSAGES.NUMBER_TYPE).min(0.01, MESSAGES.NUMBER_MIN),
          quantity: yup
            .number()
            .nullable(true)
            .typeError(MESSAGES.NUMBER_TYPE)
            .min(1, MESSAGES.NUMBER_MIN)
            .required(MESSAGES.REQUIRED)
        })
    })
  ),
  extraMaterialsDetails: yup.array().of(
    yup.object().shape({
      cost: yup.number().required(MESSAGES.REQUIRED).typeError(MESSAGES.NUMBER_TYPE).min(0.01, MESSAGES.NUMBER_MIN),
      measurement: yup.string().required(MESSAGES.REQUIRED),
      leadTime: yup.string().typeError(MESSAGES.REQUIRED).required(MESSAGES.REQUIRED),
      quantity: yup.number().required(MESSAGES.REQUIRED).typeError(MESSAGES.NUMBER_TYPE).min(1, MESSAGES.NUMBER_MIN),
      note: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .max(250, MESSAGES.MAX_CHARACTERS)
        .nullable()
        .trim(),
      material: yup.object().shape({
        name: yup
          .string()
          .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
          .max(250, MESSAGES.MAX_CHARACTERS)
          .required(MESSAGES.REQUIRED)
          .trim(),
        brand: yup.string().matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED).nullable().trim()
      })
    })
  )
});

export const MATERIAL_ITEM_CRUD_SCHEME = yup.object().shape({
  itemNumber: yup
    .string()
    .max(200, MESSAGES.MAX_CHARACTERS)
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .nullable()
    .trim(),
  name: yup
    .string()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .max(250, MESSAGES.MAX_CHARACTERS)
    .required(MESSAGES.REQUIRED)
    .trim(),
  brand: yup
    .string()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .max(250, MESSAGES.MAX_CHARACTERS)
    .nullable()
    .trim(),
  manufacturer: yup
    .string()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .max(250, MESSAGES.MAX_CHARACTERS)
    .nullable()
    .trim()
});

export const BUSINESS_UPDATE_SCHEME = yup.object().shape({
  ein: yup.string().when('businessAddresses', (value, schema) => {
    const isLightValidation = checkCountryState(value.at(0).state, ['Canada']);
    if (isLightValidation) {
      return schema;
    }
    return schema
      .min(9, 'Enter a valid EIN')
      .nullable()
      .transform((v, o) => (o === '' ? null : v));
  }),
  businessLegalName: yup.string().max(250, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED).trim(),
  businessEntityType: yup.string().nullable(),
  businessEmail: yup
    .string()
    .email(MESSAGES.EMAIl_VALID)
    .nullable()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .trim(),
  businessStartDate: yup.string().nullable(),
  businessPhoneNumber: yup
    .string()
    .nullable()
    .min(8, MESSAGES.PHONE_VALID)
    .transform((v, o) => (o === '' ? null : v)),
  businessAddresses: yup.array().of(
    yup.object().shape({
      businessAddress: yup
        .string()
        .nullable()
        .max(250, MESSAGES.MAX_CHARACTERS)
        .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
        .trim()
        .transform((v, o) => (o === '' ? null : v)),
      city: yup
        .string()
        .nullable()
        .matches(REGEX.TEXT_SIMPLE, MESSAGES.CITY_CORRECT)
        .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
        .trim()
        .transform((v, o) => (o === '' ? null : v)),
      state: yup.string().nullable(),
      usZipCode: yup
        .string()
        .nullable()
        .when('state', (value, schema) => {
          const isLightValidation = checkCountryState(value, ['Canada']);
          if (isLightValidation) {
            return schema;
          }
          return schema.typeError(MESSAGES.NUMBER_TYPE).matches(REGEX.US_ZIP_CODE, MESSAGES.ZIP_VALID);
        })
        .transform((v, o) => (o === '' ? null : v))
    })
  )
});

export const BUSINESS_UPDATE_OATFI_SCHEME = yup.object().shape({
  name: yup.string().max(250, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED).trim(),
  businessEntityType: yup.string().required(MESSAGES.REQUIRED),
  businessStartDateMonth: yup.string().required(MESSAGES.REQUIRED),
  businessStartDateYear: yup.string().required(MESSAGES.REQUIRED),
  contactEmail: yup
    .string()
    .required(MESSAGES.REQUIRED)
    .email(MESSAGES.EMAIl_VALID)
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .trim(),
  contactFirstName: yup.string().max(250, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED).trim(),
  contactLastName: yup.string().max(250, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED).trim(),
  contactPhoneNumber: yup.string().required(MESSAGES.REQUIRED).min(8, MESSAGES.PHONE_VALID),
  state: yup.string().required(MESSAGES.REQUIRED),
  city: yup
    .string()
    .matches(REGEX.TEXT_SIMPLE, MESSAGES.CITY_CORRECT)
    .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
    .required(MESSAGES.REQUIRED)
    .trim(),
  postalCode: yup.string().required(MESSAGES.REQUIRED).matches(REGEX.US_ZIP_CODE, MESSAGES.ZIP_VALID),
  streetAddress: yup.string().required(MESSAGES.REQUIRED)
});

export const BUSINESS_OWNER_SCHEME = yup.object().shape({
  firstName: yup
    .string()
    .max(250, MESSAGES.MAX_CHARACTERS)
    .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
    .matches(REGEX.TEXT_SIMPLE, MESSAGES.VALUE_VALID)
    .required(MESSAGES.REQUIRED)
    .trim(),
  lastName: yup
    .string()
    .max(250, MESSAGES.MAX_CHARACTERS)
    .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
    .matches(REGEX.TEXT_SIMPLE, MESSAGES.VALUE_VALID)
    .required(MESSAGES.REQUIRED)
    .trim(),
  dateOfBirth: yup.string().required(MESSAGES.REQUIRED),
  socialSecurityNumber: yup.string().when('businessOwnerAddresses', (value, schema) => {
    const isLightValidation = checkCountryState(value.at(0).state, ['Canada']);
    if (isLightValidation) {
      return schema;
    }
    return schema.min(11, 'Enter valid social security number').required(MESSAGES.REQUIRED);
  }),
  ownershipPercentage: yup
    .number()
    .typeError(MESSAGES.NUMBER_TYPE)
    .min(1, MESSAGES.PERCENTAGE_VALID)
    .max(100, MESSAGES.PERCENTAGE_VALID)
    .required(MESSAGES.REQUIRED),
  businessOwnerAddresses: yup.array().of(
    yup.object().shape({
      personalAddress: yup
        .string()
        .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
        .required(MESSAGES.REQUIRED)
        .trim(),
      city: yup
        .string()
        .matches(REGEX.TEXT_SIMPLE, MESSAGES.CITY_CORRECT)
        .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
        .required(MESSAGES.REQUIRED)
        .trim(),
      state: yup.string().required(MESSAGES.REQUIRED),
      usZipCode: yup
        .string()
        .required(MESSAGES.REQUIRED)
        .when('state', (value, schema) => {
          const isLightValidation = checkCountryState(value, ['Canada']);
          if (isLightValidation) {
            return schema;
          }
          return schema.matches(REGEX.US_ZIP_CODE, MESSAGES.ZIP_VALID);
        })
    })
  )
});

export const GENERAL_INFO_SHEME = ({ mode }) => {
  if (mode === 'SINGLE_REQUEST') {
    return yup.object().shape({
      requestName: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .max(250, MESSAGES.MAX_CHARACTERS)
        .required(MESSAGES.REQUIRED)
        .trim(),
      // expectedMaterialCostBudget: yup
      //   .number()
      //   .typeError(MESSAGES.NUMBER_TYPE)
      //   .min(0, MESSAGES.AMOUNT_MIN)
      //   .required(MESSAGES.REQUIRED),
      // expectedMaterialCostRequest: yup
      //   .number()
      //   .typeError(MESSAGES.NUMBER_TYPE)
      //   .min(1, MESSAGES.AMOUNT_MIN)
      //   .required(MESSAGES.REQUIRED),
      totalProjectContractValue: yup
        .number()
        .typeError(MESSAGES.NUMBER_TYPE)
        .min(0, MESSAGES.AMOUNT_MIN)
        .required(MESSAGES.REQUIRED),
      materialsDetails: yup.array().of(
        yup.object().shape({
          material: yup.object().shape({
            name: yup
              .string()
              .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
              .max(250, MESSAGES.MAX_CHARACTERS)
              .required(MESSAGES.REQUIRED)
              .trim(),
            itemNumber: yup
              .string()
              .max(200, MESSAGES.MAX_CHARACTERS)
              .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
              .nullable()
              .trim()
          }),
          measurement: yup.string().required(MESSAGES.REQUIRED),
          quantity: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(1, MESSAGES.AMOUNT_MIN).required(MESSAGES.REQUIRED)
        })
      ),
      businessName: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .required(MESSAGES.REQUIRED)
        .trim(),
      contactName: yup.string().required(MESSAGES.REQUIRED).trim(),
      address: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .required(MESSAGES.REQUIRED)
        .trim(),
      city: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .matches(REGEX.TEXT_SIMPLE, MESSAGES.CITY_CORRECT)
        .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
        .required(MESSAGES.REQUIRED)
        .trim(),
      state: yup.string().required(MESSAGES.REQUIRED),
      usZipCode: yup
        .string()
        .required(MESSAGES.REQUIRED)
        .when('state', (value, schema) => {
          const isLightValidation = checkCountryState(value, ['Canada']);
          if (isLightValidation) {
            return schema;
          }
          return schema.matches(REGEX.US_ZIP_CODE, MESSAGES.ZIP_VALID);
        }),
      leadTime: yup.string().required(MESSAGES.REQUIRED)
    });
  }
  if (mode === 'REPLENISHMENT_REQUEST') {
    return yup.object().shape({
      requestName: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .max(250, MESSAGES.MAX_CHARACTERS)
        .required(MESSAGES.REQUIRED)
        .trim(),
      // expectedMaterialCostBudget: yup
      //   .number()
      //   .typeError(MESSAGES.NUMBER_TYPE)
      //   .min(0, MESSAGES.AMOUNT_MIN)
      //   .required(MESSAGES.REQUIRED),
      // expectedMaterialCostRequest: yup
      //   .number()
      //   .typeError(MESSAGES.NUMBER_TYPE)
      //   .min(1, MESSAGES.AMOUNT_MIN)
      //   .required(MESSAGES.REQUIRED),
      totalProjectContractValue: yup
        .number()
        .typeError(MESSAGES.NUMBER_TYPE)
        .min(0, MESSAGES.AMOUNT_MIN)
        .required(MESSAGES.REQUIRED),
      materialsDetails: yup.array().of(
        yup.object().shape({
          material: yup.object().shape({
            name: yup
              .string()
              .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
              .max(250, MESSAGES.MAX_CHARACTERS)
              .required(MESSAGES.REQUIRED)
              .trim(),
            itemNumber: yup
              .string()
              .max(200, MESSAGES.MAX_CHARACTERS)
              .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
              .nullable()
              .trim()
          }),
          measurement: yup.string().required(MESSAGES.REQUIRED),
          quantity: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(1, MESSAGES.AMOUNT_MIN).required(MESSAGES.REQUIRED)
        })
      ),
      businessName: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .required(MESSAGES.REQUIRED)
        .trim(),
      contactName: yup.string().required(MESSAGES.REQUIRED).trim(),
      address: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .required(MESSAGES.REQUIRED)
        .trim(),
      city: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .matches(REGEX.TEXT_SIMPLE, MESSAGES.CITY_CORRECT)
        .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
        .required(MESSAGES.REQUIRED)
        .trim(),
      state: yup.string().required(MESSAGES.REQUIRED),
      usZipCode: yup
        .string()
        .required(MESSAGES.REQUIRED)
        .when('state', (value, schema) => {
          const isLightValidation = checkCountryState(value, ['Canada']);
          if (isLightValidation) {
            return schema;
          }
          return schema.matches(REGEX.US_ZIP_CODE, MESSAGES.ZIP_VALID);
        }),
      leadTime: yup.string().required(MESSAGES.REQUIRED)
    });
  }
  return yup.object().shape({
    projectName: yup
      .string()
      .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
      .max(250, MESSAGES.MAX_CHARACTERS)
      .required(MESSAGES.REQUIRED)
      .trim(),
    requestName: yup
      .string()
      .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
      .max(250, MESSAGES.MAX_CHARACTERS)
      .required(MESSAGES.REQUIRED)
      .trim(),
    expectedMaterialCostBudget: yup
      .number()
      .typeError(MESSAGES.NUMBER_TYPE)
      .min(0, MESSAGES.AMOUNT_MIN)
      .required(MESSAGES.REQUIRED),
    // expectedMaterialCostRequest: yup
    //   .number()
    //   .typeError(MESSAGES.NUMBER_TYPE)
    //   .min(1, MESSAGES.AMOUNT_MIN)
    //   .required(MESSAGES.REQUIRED),
    totalProjectContractValue: yup
      .number()
      .typeError(MESSAGES.NUMBER_TYPE)
      .min(0, MESSAGES.AMOUNT_MIN)
      .required(MESSAGES.REQUIRED),
    expectedProjectStartDate: yup.string().required(MESSAGES.REQUIRED),
    materialsDetails: yup.array().of(
      yup.object().shape({
        material: yup.object().shape({
          name: yup
            .string()
            .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
            .max(250, MESSAGES.MAX_CHARACTERS)
            .required(MESSAGES.REQUIRED)
            .trim(),
          itemNumber: yup
            .string()
            .max(200, MESSAGES.MAX_CHARACTERS)
            .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
            .nullable()
            .trim()
        }),
        measurement: yup.string().required(MESSAGES.REQUIRED),
        quantity: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(1, MESSAGES.AMOUNT_MIN).required(MESSAGES.REQUIRED)
      })
    ),
    businessName: yup
      .string()
      .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
      .required(MESSAGES.REQUIRED)
      .trim(),
    contactName: yup.string().required(MESSAGES.REQUIRED).trim(),
    address: yup
      .string()
      .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
      .required(MESSAGES.REQUIRED)
      .trim(),
    city: yup
      .string()
      .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
      .matches(REGEX.TEXT_SIMPLE, MESSAGES.CITY_CORRECT)
      .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
      .required(MESSAGES.REQUIRED)
      .trim(),
    state: yup.string().required(MESSAGES.REQUIRED),
    usZipCode: yup
      .string()
      .required(MESSAGES.REQUIRED)
      .when('state', (value, schema) => {
        const isLightValidation = checkCountryState(value, ['Canada']);
        if (isLightValidation) {
          return schema;
        }
        return schema.matches(REGEX.US_ZIP_CODE, MESSAGES.ZIP_VALID);
      }),
    leadTime: yup.string().required(MESSAGES.REQUIRED)
  });
};

export const LOCATION_WAREHOUSE_SCHEME = yup.object().shape({
  name: yup
    .string()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .max(200, MESSAGES.MAX_CHARACTERS)
    .required(MESSAGES.REQUIRED)
    .trim(),
  // location: yup.string().max(200, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED),
  address: yup
    .string()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
    .required(MESSAGES.REQUIRED)
    .trim(),
  city: yup
    .string()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .matches(REGEX.TEXT_SIMPLE, MESSAGES.CITY_CORRECT)
    .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
    .required(MESSAGES.REQUIRED)
    .trim(),
  state: yup.string().required(MESSAGES.REQUIRED),
  usZipCode: yup
    .string()
    .required(MESSAGES.REQUIRED)
    .when('state', (value, schema) => {
      const isLightValidation = checkCountryState(value, ['Canada']);
      if (isLightValidation) {
        return schema;
      }
      return schema.matches(REGEX.US_ZIP_CODE, {
        message: MESSAGES.ZIP_VALID,
        excludeEmptyString: true
      });
    })
});
export const BUSINESS_USER_SCHEME = yup.object().shape({
  email: yup
    .string()
    .required(MESSAGES.REQUIRED)
    .email(MESSAGES.EMAIl_VALID)
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .trim(),
  businessLegalName: yup
    .string()
    .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
    .required(MESSAGES.REQUIRED)
    .trim(),
  firstName: yup
    .string()
    .max(250, MESSAGES.MAX_CHARACTERS)
    .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
    .required(MESSAGES.REQUIRED)
    .trim(),
  lastName: yup
    .string()
    .max(250, MESSAGES.MAX_CHARACTERS)
    .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
    .required(MESSAGES.REQUIRED)
    .trim()
});

export const MIXED_BID_SETTINGS = yup.object().shape({
  payDirectlyUrl: yup.string().matches(REGEX.URL, MESSAGES.URL_VALID).required(MESSAGES.REQUIRED)
});

export const NOTE_SCHEME = yup.object().shape({
  note: yup.string().matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED).max(250, MESSAGES.MAX_CHARACTERS)
});

export const NOTE_REQUIRED_SCHEME = yup.object().shape({
  note: yup
    .string()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .max(250, MESSAGES.MAX_CHARACTERS)
    .required(MESSAGES.REQUIRED)
    .trim()
});

export const EXTRA_MATERIALS_SCHEME = yup.array().of(
  yup.object().shape({
    material: yup.object().shape({
      name: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .max(250, MESSAGES.MAX_CHARACTERS)
        .required(MESSAGES.REQUIRED)
        .trim(),
      itemNumber: yup
        .string()
        .max(200, MESSAGES.MAX_CHARACTERS)
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .nullable()
        .trim()
    }),
    measurement: yup.string().required(MESSAGES.REQUIRED),
    quantity: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(1, MESSAGES.AMOUNT_MIN).required(MESSAGES.REQUIRED)
  })
);

export const MATERIAL_MORE_INFO_SCHEME = yup.object().shape({
  manufacturer: yup
    .string()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .max(250, MESSAGES.MAX_CHARACTERS)
    .nullable()
    .trim(),
  note: yup
    .string()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .max(250, MESSAGES.MAX_CHARACTERS)
    .nullable()
    .trim()
});

export const MANAGE_PERCENTAGE_TAX = yup.object().shape({
  plyCommissionPercentage: yup
    .number()
    .typeError(MESSAGES.NUMBER_TYPE)
    .min(0.01, MESSAGES.PERCENTAGE_VALID)
    .max(100, MESSAGES.PERCENTAGE_VALID)
    .required(MESSAGES.REQUIRED)
});

export const USER_NAME_SCHEME = yup.object().shape({
  firstName: yup.string().max(250, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED).trim(),
  lastName: yup.string().max(250, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED).trim()
});

export const PROJECT_SCHEME = yup.object().shape({
  archived: yup.boolean(),
  expectedMaterialCostBudget: yup
    .number()
    .typeError(MESSAGES.NUMBER_TYPE)
    .min(1, MESSAGES.AMOUNT_MIN)
    .required(MESSAGES.REQUIRED),
  expectedProjectStartDate: yup.string().required(MESSAGES.REQUIRED),
  projectName: yup
    .string()
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .max(250, MESSAGES.MAX_CHARACTERS)
    .required(MESSAGES.REQUIRED)
    .trim(),
  totalProjectContractValue: yup
    .number()
    .typeError(MESSAGES.NUMBER_TYPE)
    .min(1, MESSAGES.AMOUNT_MIN)
    .required(MESSAGES.REQUIRED),
  useOfFunds: yup.string().max(250, MESSAGES.MAX_CHARACTERS)
});

export const RFQ_CRUD_SCHEME = yup.object().shape({
  fulfillmentInfo: yup.object().shape({
    businessName: yup.string().required(MESSAGES.REQUIRED).trim(),
    contactName: yup.string().required(MESSAGES.REQUIRED).trim(),
    address: yup
      .string()
      .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
      .required(MESSAGES.REQUIRED)
      .trim(),
    city: yup
      .string()
      .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
      .matches(REGEX.TEXT_SIMPLE, MESSAGES.CITY_CORRECT)
      .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
      .required(MESSAGES.REQUIRED)
      .trim(),
    state: yup.string().required(MESSAGES.REQUIRED),
    usZipCode: yup
      .string()
      .required(MESSAGES.REQUIRED)
      .when('state', (value, schema) => {
        const isLightValidation = checkCountryState(value, ['Canada']);
        if (isLightValidation) {
          return schema;
        }
        return schema.matches(REGEX.US_ZIP_CODE, MESSAGES.ZIP_VALID);
      }),
    leadTime: yup.string().required(MESSAGES.REQUIRED)
  }),
  materialsBuyRequest: yup.object().shape({
    // expectedMaterialCost: yup.number().min(0).required(MESSAGES.REQUIRED),
    materialsBuyRequestName: yup
      .string()
      .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
      .trim()
      .max(250, MESSAGES.MAX_CHARACTERS)
      .required(MESSAGES.REQUIRED)
      .trim(),
    materialsBuyRequestRfqStatus: yup.string().required(MESSAGES.REQUIRED),
    materialsDetails: yup.array().of(
      yup.object().shape({
        material: yup.object().shape({
          brand: yup
            .string()
            .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
            .max(250, MESSAGES.MAX_CHARACTERS)
            .nullable()
            .trim(),
          itemNumber: yup
            .string()
            .max(200, MESSAGES.MAX_CHARACTERS)
            .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
            .nullable()
            .trim(),
          manufacturer: yup
            .string()
            .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
            .max(250, MESSAGES.MAX_CHARACTERS)
            .nullable()
            .trim(),
          materialCategory: yup.string().nullable(),
          name: yup
            .string()
            .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
            .trim()
            .max(250, MESSAGES.MAX_CHARACTERS)
            .required(MESSAGES.REQUIRED)
            .trim()
        }),
        measurement: yup.string().required(MESSAGES.REQUIRED),
        note: yup.string().max(250, MESSAGES.MAX_CHARACTERS).nullable().trim(),
        quantity: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(1, MESSAGES.AMOUNT_MIN).required(MESSAGES.REQUIRED)
      })
    ),
    noteForSupplier: yup.string().matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED).max(255).nullable()
  })
});

export const GRADER_FAKE_RFQ_CRUD_SCHEME = yup.object().shape({
  // fulfillmentInfo: yup.object().shape({
  //   businessName: yup.string().required(MESSAGES.REQUIRED),
  //   contactName: yup.string().required(MESSAGES.REQUIRED),
  //   address: yup.string().required(MESSAGES.REQUIRED),
  //   city: yup
  //     .string()
  //     .matches(REGEX.TEXT_SIMPLE, MESSAGES.CITY_CORRECT)
  //     .matches(REGEX.SEQUENTIALLY_SPACES, MESSAGES.VALUE_VALID)
  //     .required(MESSAGES.REQUIRED),
  //   state: yup.string().required(MESSAGES.REQUIRED),
  //   usZipCode: yup.string().matches(REGEX.US_ZIP_CODE, MESSAGES.ZIP_VALID).required(MESSAGES.REQUIRED),
  //   leadTime: yup.string().required(MESSAGES.REQUIRED)
  // }),
  materialsBuyRequest: yup.object().shape({
    // expectedMaterialCost: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(0).required(MESSAGES.REQUIRED),
    // materialsBuyRequestName: yup.string().trim().max(250, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED),
    // materialsBuyRequestRfqStatus: yup.string().required(MESSAGES.REQUIRED),
    materialsDetails: yup.array().of(
      yup.object().shape({
        material: yup.object().shape({
          brand: yup.string().max(250, MESSAGES.MAX_CHARACTERS).nullable().trim(),
          itemNumber: yup
            .string()
            .max(200, MESSAGES.MAX_CHARACTERS)
            .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
            .nullable()
            .trim(),
          manufacturer: yup.string().max(250, MESSAGES.MAX_CHARACTERS).nullable().trim(),
          materialCategory: yup.string().nullable(),
          name: yup.string().trim().max(250, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED).trim()
        }),
        measurement: yup.string().required(MESSAGES.REQUIRED),
        note: yup.string().max(250, MESSAGES.MAX_CHARACTERS).nullable().trim(),
        quantity: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(1, MESSAGES.AMOUNT_MIN).required(MESSAGES.REQUIRED)
      })
    )
    // noteForSupplier: yup.string().max(255).nullable()
  })
});

export const INVITE_USER_SCHEME = yup.object().shape({
  email: yup
    .string()
    .email(MESSAGES.EMAIl_VALID)
    .required(MESSAGES.REQUIRED)
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .trim(),
  role: yup.string().required(MESSAGES.REQUIRED)
});

export const BUSINESS_MEMBER_SCHEME = yup.object().shape({
  email: yup
    .string()
    .email(MESSAGES.EMAIl_VALID)
    .required(MESSAGES.REQUIRED)
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .trim(),
  lastName: yup.string().max(100, MESSAGES.LIMIT_CHARACTERS).required(MESSAGES.REQUIRED).trim(),
  firstName: yup.string().max(100, MESSAGES.LIMIT_CHARACTERS).required(MESSAGES.REQUIRED).trim(),
  role: yup.string().required(MESSAGES.REQUIRED)
});

export const ADDITIONAL_INVOICE_ITEMS_SCHEME = yup.object().shape({
  additionalInvoiceItems: yup.array().of(
    yup.object().shape({
      cost: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(0, MESSAGES.NUMBER_MIN).required(MESSAGES.REQUIRED),
      name: yup
        .string()
        .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
        .max(250, MESSAGES.MAX_CHARACTERS)
        .required(MESSAGES.REQUIRED)
        .trim()
    })
  )
});

export const LOGISTIC_CONFIGURATION = yup.object().shape({
  materialShippingDTO: yup.array().of(
    yup.object().shape({
      url: yup.string().url(MESSAGES.URL_VALID).max(250, MESSAGES.MAX_CHARACTERS).trim(),
      shippingNumber: yup.string().max(250, MESSAGES.MAX_CHARACTERS).trim(),
      note: yup.string().max(250, MESSAGES.MAX_CHARACTERS).trim(),
      name: yup.string().max(250, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED).trim()
    })
  ),
  mainNote: yup.string().max(250, MESSAGES.MAX_CHARACTERS).trim()
});

export const INVITE_MEMBER_SCHEME = yup.object().shape({
  email: yup
    .string()
    .email(MESSAGES.EMAIl_VALID)
    .typeError(MESSAGES.REQUIRED)
    .matches(REGEX.CYRYLIC_VALIDATION, MESSAGES.CYRYLIC_NOT_ALLOWED)
    .required(MESSAGES.REQUIRED)
    .trim(),
  firstName: yup
    .string()
    .max(100, MESSAGES.LIMIT_CHARACTERS)
    .typeError(MESSAGES.REQUIRED)
    .required(MESSAGES.REQUIRED)
    .trim(),
  lastName: yup
    .string()
    .max(100, MESSAGES.LIMIT_CHARACTERS)
    .typeError(MESSAGES.REQUIRED)
    .required(MESSAGES.REQUIRED)
    .trim(),
  slackMemberId: yup.string().nullable().max(100, MESSAGES.LIMIT_CHARACTERS).trim(),
  role: yup.string().typeError(MESSAGES.REQUIRED).required(MESSAGES.REQUIRED),
  password: yup
    .string()
    .min(8, MESSAGES.PASSWORD_MIN_LENGTH)
    .matches(REGEX.PASSWORD_COMPLEXITY, MESSAGES.PASSWORD_COMPLEXITY)
    .matches(REGEX.NOT_ALLOW_SPACE, 'Please remove the space')
    .required(MESSAGES.REQUIRED)
    .trim()
});

export const UPDATE_MEMBER_SCHEME = yup.object().shape({
  firstName: yup
    .string()
    .max(100, MESSAGES.LIMIT_CHARACTERS)
    .typeError(MESSAGES.REQUIRED)
    .required(MESSAGES.REQUIRED)
    .trim(),
  lastName: yup
    .string()
    .max(100, MESSAGES.LIMIT_CHARACTERS)
    .typeError(MESSAGES.REQUIRED)
    .required(MESSAGES.REQUIRED)
    .trim(),
  slackMemberId: yup.string().nullable().max(100, MESSAGES.LIMIT_CHARACTERS).trim(),
  role: yup.string().typeError(MESSAGES.REQUIRED).required(MESSAGES.REQUIRED)
});

export const MANAGE_BUSINESS_SUBSCRIPTION = yup.object().shape({
  trucksLimit: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(0, MESSAGES.NUMBER_MIN).required(MESSAGES.REQUIRED),
  warehousesLimit: yup.number().typeError(MESSAGES.NUMBER_TYPE).min(0, MESSAGES.NUMBER_MIN).required(MESSAGES.REQUIRED),
  plyCommissionPercentage: yup
    .number()
    .typeError(MESSAGES.NUMBER_TYPE)
    .min(0.01, MESSAGES.PERCENTAGE_VALID)
    .max(100, MESSAGES.PERCENTAGE_VALID)
    .required(MESSAGES.REQUIRED),
  stockPrice: yup
    .number()
    .nullable()
    .typeError(MESSAGES.NUMBER_TYPE)
    .min(0, MESSAGES.NUMBER_MIN)
    .transform((v, o) => (o === '' ? null : v)),
  stripeSubscriptionId: yup.string().max(150, MESSAGES.LIMIT_CHARACTERS).nullable(),
  stripeCustomerId: yup.string().max(150, MESSAGES.LIMIT_CHARACTERS).nullable()
});

export const SYNC_FSM_SCHEME = yup.object().shape({
  timeFrom: yup.string().required(MESSAGES.REQUIRED),
  location: yup.object().when('service', (value, schema) => {
    if ([SERVICE_INTEGRATION.SERVICE_TITAN].includes(value)) {
      return schema.typeError(MESSAGES.REQUIRED).required(MESSAGES.REQUIRED);
    }
    return schema.nullable();
  })
});

export const ONE_FIELD_REQUIRED = (settings = {}) => {
  const { fieldName = 'name' } = settings;
  return yup.object().shape({
    [fieldName]: yup.string().max(200, MESSAGES.MAX_CHARACTERS).required(MESSAGES.REQUIRED)
  });
};

export const EMPTY_SCHEME = yup.object().shape({});
export const EMPTY_ARRAY_SCHEME = yup.array();
