import { ModalLayout } from 'ui-component/modals';
import MaterialMoreInfo from './MaterialMoreInfo';

const MaterialMoreInfoModal = ({ state, onSuccessCallback, ...modalProps }) => {
  return (
    <ModalLayout {...modalProps} maxWidth="sm">
      <MaterialMoreInfo state={state} onSuccessCallback={onSuccessCallback} />
    </ModalLayout>
  );
};

export default MaterialMoreInfoModal;
