import { USER_ROLES, OFFER_STATUSES, RFQ_FINANCING_TYPE, OFFER_TYPE } from 'constants/enums';
import { useSettingsStore } from 'store/atoms/settingsStore';
import { convertAdminRole } from 'utils/statusConvert';

export const BUSINESS_ENTITY_TYPE = [
  { value: 'LLC', label: 'LLC' },
  { value: 'SOLE_PARTNERSHIP', label: 'Sole Proprietorship' },
  { value: 'PARTNERSHIP', label: 'Partnership' },
  { value: 'CORPORATION', label: 'Corporation' },
  { value: 'COOPERATIVE', label: 'Cooperative' },
  { value: 'NON_PROFIT', label: 'Non-profit' },
  { value: 'JOINT_VENTURE', label: 'Joint Venture' },
  { value: 'I_AM_NOT_SURE', label: 'I’m not sure' },
  { value: 'I_HAVE_NOT_REGISTERED_IT_YET', label: 'I haven’t registered it yet' }
];

export const STATE_USA = [
  { value: 'ALABAMA', label: 'Alabama (USA)', shortValue: 'AL' },
  { value: 'ALASKA', label: 'Alaska (USA)', shortValue: 'AK' },
  { value: 'ARIZONA', label: 'Arizona (USA)', shortValue: 'AZ' },
  { value: 'ARKANSAS', label: 'Arkansas (USA)', shortValue: 'AR' },
  { value: 'CALIFORNIA', label: 'California (USA)', shortValue: 'CA' },
  { value: 'COLORADO', label: 'Colorado (USA)', shortValue: 'CO' },
  { value: 'CONNECTICUT', label: 'Connecticut (USA)', shortValue: 'CT' },
  { value: 'DELAWARE', label: 'Delaware (USA)', shortValue: 'DE' },
  { value: 'FLORIDA', label: 'Florida (USA)', shortValue: 'FL' },
  { value: 'GEORGIA', label: 'Georgia (USA)', shortValue: 'GA' },
  { value: 'HAWAII', label: 'Hawaii (USA)', shortValue: 'HI' },
  { value: 'IDAHO', label: 'Idaho (USA)', shortValue: 'ID' },
  { value: 'ILLINOIS', label: 'Illinois (USA)', shortValue: 'IL' },
  { value: 'INDIANA', label: 'Indiana (USA)', shortValue: 'IN' },
  { value: 'IOWA', label: 'Iowa (USA)', shortValue: 'IA' },
  { value: 'KANSAS', label: 'Kansas (USA)', shortValue: 'KS' },
  { value: 'KENTUCKY', label: 'Kentucky (USA)', shortValue: 'KY' },
  { value: 'LOUISIANA', label: 'Louisiana (USA)', shortValue: 'LA' },
  { value: 'MAINE', label: 'Maine (USA)', shortValue: 'ME' },
  { value: 'MARYLAND', label: 'Maryland (USA)', shortValue: 'MD' },
  { value: 'MASSACHUSETTS', label: 'Massachusetts (USA)', shortValue: 'MA' },
  { value: 'MICHIGAN', label: 'Michigan (USA)', shortValue: 'MI' },
  { value: 'MINNESOTA', label: 'Minnesota (USA)', shortValue: 'MN' },
  { value: 'MISSISSIPPI', label: 'Mississippi (USA)', shortValue: 'MS' },
  { value: 'MISSOURI', label: 'Missouri (USA)', shortValue: 'MO' },
  { value: 'MONTANA', label: 'Montana (USA)', shortValue: 'MT' },
  { value: 'NEBRASKA', label: 'Nebraska (USA)', shortValue: 'NE' },
  { value: 'NEVADA', label: 'Nevada (USA)', shortValue: 'NV' },
  { value: 'NEW_HAMPSHIRE', label: 'New Hampshire (USA)', shortValue: 'NH' },
  { value: 'NEW_JERSEY', label: 'New Jersey (USA)', shortValue: 'NJ' },
  { value: 'NEW_MEXICO', label: 'New Mexico (USA)', shortValue: 'NM' },
  { value: 'NEW_YORK', label: 'New York (USA)', shortValue: 'NY' },
  { value: 'NORTH_CAROLINA', label: 'North Carolina (USA)', shortValue: 'NC' },
  { value: 'NORTH_DAKOTA', label: 'North Dakota (USA)', shortValue: 'ND' },
  { value: 'OHIO', label: 'Ohio (USA)', shortValue: 'OH' },
  { value: 'OKLAHOMA', label: 'Oklahoma (USA)', shortValue: 'OK' },
  { value: 'OREGON', label: 'Oregon (USA)', shortValue: 'OR' },
  { value: 'PENNSYLVANIA', label: 'Pennsylvania (USA)', shortValue: 'PA' },
  { value: 'RHODE_ISLAND', label: 'Rhode Island (USA)', shortValue: 'RI' },
  { value: 'SOUTH_CAROLINA', label: 'South Carolina (USA)', shortValue: 'SC' },
  { value: 'SOUTH_DAKOTA', label: 'South Dakota (USA)', shortValue: 'SD' },
  { value: 'TENNESSEE', label: 'Tennessee (USA)', shortValue: 'TN' },
  { value: 'TEXAS', label: 'Texas (USA)', shortValue: 'TX' },
  { value: 'UTAH', label: 'Utah (USA)', shortValue: 'UT' },
  { value: 'VERMONT', label: 'Vermont (USA)', shortValue: 'VT' },
  { value: 'VIRGINIA', label: 'Virginia (USA)', shortValue: 'VA' },
  { value: 'WASHINGTON', label: 'Washington (USA)', shortValue: 'WA' },
  { value: 'WEST_VIRGINIA', label: 'West Virginia (USA)', shortValue: 'WV' },
  { value: 'WISCONSIN', label: 'Wisconsin (USA)', shortValue: 'WI' },
  { value: 'WYOMING', label: 'Wyoming (USA)', shortValue: 'WY' },
  { value: 'DISTRICT_OF_COLUMBIA', label: 'District of Columbia (USA)', shortValue: 'DC' }
];

export const STATE_CANADA = [
  { value: 'CANADA_ONTARIO', label: 'Ontario (Canada)' },
  { value: 'CANADA_QUEBEC', label: 'Quebec (Canada)' },
  { value: 'CANADA_NOVA_SCOTIA', label: 'Nova Scotia (Canada)' },
  { value: 'CANADA_NEW_BRUNSWICK', label: 'New Brunswick (Canada)' },
  { value: 'CANADA_MANITOBA', label: 'Manitoba (Canada)' },
  { value: 'CANADA_BRITISH_COLUMBIA', label: 'British Columbia (Canada)' },
  { value: 'CANADA_PRINCE_EDWARD_ISLAND', label: 'Prince Edward Island (Canada)' },
  { value: 'CANADA_SASKATCHEWAN', label: 'Saskatchewan (Canada)' },
  { value: 'CANADA_ALBERTA', label: 'Alberta (Canada)' },
  { value: 'CANADA_NEWFOUNDLAND_AND_LABRADOR', label: 'Newfoundland and Labrador (Canada)' },
  { value: 'CANADA_NORTHWEST_TERRITORIES', label: 'Northwest Territories (Canada)' },
  { value: 'CANADA_YUKON', label: 'Yukon (Canada)' },
  { value: 'CANADA_NUNAVUT', label: 'Nunavut (Canada)' }
];

export const STATES = [...STATE_USA, ...STATE_CANADA];

export const USE_OF_FUNDS_SELECT = [
  { value: 'EXPAND_BUSINESS', label: 'Expand business' },
  { value: 'IMPORT_GOODS', label: 'Import goods' },
  { value: 'PURCHASE_A_VEHICLE', label: 'Purchase a vehicle' },
  { value: 'PROMOTE_BUSINESS', label: 'Promote business' },
  { value: 'IMPROVE_CASH_FLOW', label: 'Improve cash flow' },
  { value: 'PAYROLL', label: 'Payroll' },
  { value: 'PAY_TAXES', label: 'Pay taxes' },
  { value: 'PURCHASE_EQUIPMENT', label: 'Purchase equipment' },
  { value: 'PURCHASE_INVENTORY', label: 'Purchase inventory' },
  { value: 'REMODEL', label: 'Remodel' },
  { value: 'PURCHASE_REAL_ESTATE', label: 'Purchase real estate' },
  { value: 'OTHER', label: 'Other' }
];

export const MATERIAL_COVERAGE_STATUSES = [
  { value: 'ALL_MATERIALS', label: 'All materials' },
  { value: 'PARTIAL_MATERIALS', label: 'Partial materials' }
];

export const MATERIAL_REQUEST_STATUSES = [
  { value: 'NEW', label: 'New' },
  { value: 'ACCEPTED', label: 'Accepted' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'DECLINED', label: 'Declined' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'LOST', label: 'Lost' }
];

export const MEASUREMENT_SELECT = [
  { value: 'EACH', label: 'Each' },
  { value: 'FEET', label: 'Feet' },
  { value: 'INCHES', label: 'Inches' },
  { value: 'YARDS', label: 'Yards' },
  { value: 'PACKAGE', label: 'Package' },
  { value: 'BOXES', label: 'Boxes' }
];

export const MATERIAL_CATEGORY_SELECT = [
  { value: 'ADHESIVES_SEALANTS_AND_TAPE', label: 'Adhesives sealants and tape' },
  { value: 'AIR_VENTILATION', label: 'Air ventilation' },
  { value: 'APPLIANCES', label: 'Appliances' },
  { value: 'BLACK_PIPE', label: 'Black Pipe' },
  { value: 'COMMERCIAL_PLUMBING', label: 'Commercial plumbing' },
  { value: 'COPPER_FITTING', label: 'Copper Fitting' },
  { value: 'COPPER_PIPE', label: 'Copper Pipe' },
  { value: 'DUCT', label: 'Duct' },
  { value: 'ELECTRICAL', label: 'Electrical' },
  { value: 'ELECTRICAL_FITTINGS', label: 'Electrical Fittings' },
  { value: 'ELECTRICAL_PIPE', label: 'Electrical Pipe' },
  { value: 'EQUIPMENT', label: 'Equipment' },
  { value: 'FACILITY_MAINTENANCE', label: 'Facility maintenance' },
  { value: 'FASTENERS', label: 'Fasteners' },
  { value: 'FIRE_PROTECTION', label: 'Fire protection' },
  { value: 'FIRE_SPRINKLERS', label: 'Fire sprinklers' },
  { value: 'FITTINGS', label: 'Fittings' },
  { value: 'FIXTURES', label: 'Fixtures' },
  { value: 'GAL_FITTINGS', label: 'Gal Fittings' },
  { value: 'GAL_PIPE', label: 'Gal Pipe' },
  { value: 'GENERAL', label: 'General' },
  { value: 'HEATING_AND_COOLING', label: 'Heating and cooling' },
  { value: 'HVAC', label: 'HVAC' },
  { value: 'HVAC_R_VALVES', label: 'HVAC R valves' },
  { value: 'IRRIGATION', label: 'Irrigation' },
  { value: 'LIGHTING', label: 'Lighting' },
  { value: 'MALLABLE_FITTINGS', label: 'Mallable Fittings (black/Gas)' },
  { value: 'MEGA_PRESS_FITTINGS', label: 'Mega Press Fittings' },
  { value: 'MISC', label: 'Misc' },
  { value: 'MOTORS_AND_ACCESSORIES', label: 'Motors and accessories' },
  { value: 'PARTS', label: 'Parts' },
  { value: 'PIPE_AND_TUBE_FITTINGS', label: 'Pipe and tube fittings' },
  { value: 'PIPE_TUBING_AND_HOSES', label: 'Pipe tubing and hoses' },
  { value: 'PLUMBING', label: 'Plumbing' },
  { value: 'PRO_PRESS_FITTINGS', label: 'Pro Press Fittings' },
  { value: 'PUMPS_WELLS_AND_SEPTIC', label: 'Pumps wells and septic' },
  { value: 'PVC_FITTINGS', label: 'PVC Fittings' },
  { value: 'PVC_PIPE', label: 'PVC Pipe' },
  { value: 'RAW_MATERIAL', label: 'Raw material' },
  { value: 'RESIDENTIAL_PLUMBING', label: 'Residential plumbing' },
  { value: 'SAFETY', label: 'Safety' },
  { value: 'TOOL', label: 'Tool' },
  { value: 'TOOLS_AND_INSTRUMENTS', label: 'Tools and instruments' },
  { value: 'VALVES', label: 'Valves' },
  { value: 'WATER_HEATERS', label: 'Water heaters' },
  { value: 'WATERWORKS', label: 'Waterworks' }
];

export const OFFER_TIMELINE = [
  { value: 'ONE_DAY', label: '1 Day' },
  { value: 'TWO_DAYS', label: '2 Days' },
  { value: 'THREE_TO_FIVE_DAYS', label: '3-5 Days' },
  { value: 'ONE_WEEK', label: '1 Week' },
  { value: 'TWO_WEEKS', label: '2 Weeks' },
  { value: 'THREE_WEEKS', label: '3 Weeks' },
  { value: 'ONE_MONTH', label: '1 Month' }
];

export const MATERIAL_LEAD_TIME = [
  { value: 'IN_STOCK', label: 'In Stock' },
  { value: 'ONE_DAY', label: '1 Day' },
  { value: 'TWO_DAYS', label: ' 2 Days' },
  { value: 'THREE_TO_FIVE_DAYS', label: '3-5 Days' },
  { value: 'ONE_WEEK', label: '1 Week' },
  { value: 'TWO_WEEKS', label: '2 Weeks' },
  { value: 'THREE_WEEKS', label: '3 Weeks' },
  { value: 'FOUR_WEEKS', label: '4 Weeks' },
  { value: 'FIVE_WEEKS', label: '5 Weeks' },
  { value: 'SIX_WEEKS', label: '6 Weeks' },
  { value: 'SEVEN_WEEKS', label: '7 Weeks' },
  { value: 'EIGHT_WEEKS', label: '8 Weeks' },
  { value: 'NINE_WEEKS', label: '9 Weeks' },
  { value: 'TEN_WEEKS', label: '10 Weeks' },
  { value: 'ELEVEN_WEEKS', label: '11 Weeks' },
  { value: 'TWELVE_WEEKS', label: '12 Weeks' },
  { value: 'THIRTEEN_WEEKS', label: '13 Weeks' },
  { value: 'FOURTEEN_WEEKS', label: '14 Weeks' },
  { value: 'FIFTEEN_WEEKS', label: '15 Weeks' },
  { value: 'SIXTEEN_WEEKS', label: '16 Weeks' },
  { value: 'SEVENTEEN_WEEKS', label: '17 Weeks' },
  { value: 'EIGHTEEN_WEEKS', label: '18 Weeks' },
  { value: 'NINETEEN_WEEKS', label: '19 Weeks' },
  { value: 'TWENTY_WEEKS', label: '20 Weeks' },
  { value: 'NOT_AVAILABLE', label: 'Not Available' }
];

export const USER_ROLES_SELECT = [
  { label: 'Admin', value: USER_ROLES.ADMIN },
  { label: 'Manager', value: USER_ROLES.MANAGER },
  { label: 'Member', value: USER_ROLES.MEMBER },
  { label: 'Tech', value: USER_ROLES.TECH }
];

export const NET_TERMS_DUE_DATE = [
  { label: '30 days', value: 'THIRTY_DAYS' },
  { label: '60 days', value: 'SIXTY_DAYS' },
  { label: '90 days', value: 'NINETY_DAYS' }
];

export const getMembersSelect = () => {
  const members = useSettingsStore.getState().members || [];

  return members.map((member) => ({
    value: member.id,
    label: `${member.firstName ? `${member.firstName} ${member.lastName} | ` : ''} ${member.email} | ${convertAdminRole(
      member.role
    )}`,
    member
  }));
};

export const OFFER_STATUS_SELECT = [
  { value: OFFER_STATUSES.PENDING, label: 'Pending' },
  { value: OFFER_STATUSES.WON, label: 'Won' },
  { value: OFFER_STATUSES.PAID, label: 'Paid' },
  { value: OFFER_STATUSES.LOST, label: 'Lost' },
  { value: OFFER_STATUSES.EXPIRED, label: 'Expired' },
  { value: OFFER_STATUSES.ARCHIVED, label: 'Archived' },
  { value: OFFER_STATUSES.DECLINED, label: 'Declined' }
];

export const RFQ_FINANCING_TYPE_SELECT = [
  { value: RFQ_FINANCING_TYPE.PAY_DIRECTLY, label: 'Pay Directly' },
  { value: RFQ_FINANCING_TYPE.NET_TERMS, label: 'Net Terms' }
];

export const OFFER_TYPE_SELECT = [
  { value: OFFER_TYPE.PRICE, label: 'By Price' },
  { value: OFFER_TYPE.LEAD_TIME, label: 'By Lead Time' },
  { value: OFFER_TYPE.CUSTOM, label: 'Custom' }
];
