import Add from '@mui/icons-material/Add';
import clsx from 'clsx';

import s from './s.module.scss';

const SIZE_CN = {
  md: s.md,
  sm: s.sm
};

const AddButton = ({ onClick, children, size = 'md' }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button type="button" className={s.add_button_wrapper} onClick={handleClick}>
      <span className={clsx(s.add_button, SIZE_CN[size])}>
        <Add fontSize="small" />
      </span>
      {children}
    </button>
  );
};

export default AddButton;
