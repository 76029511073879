import { Tabs, Tab, styled } from '@mui/material';

const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  '& .MuiButtonBase-root': {
    fontSize: '14px',
    lineHeight: '22px',
    textTransform: 'capitalize'
  },
  '& .Mui-selected': {
    color: '#5e35b1!important'
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '3px'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 60,
    width: '100%',
    backgroundColor: '#5e35b1'
  }
});

const NavTabs = ({ tabs, activeTab, onChange, isBottomLine = true }) => {
  const handleChangeTab = (_, tab) => {
    if (onChange) {
      onChange(tab);
    }
  };

  return (
    <div style={{ borderBottom: isBottomLine ? '1px solid #E3F2FD' : 'none' }}>
      <StyledTabs value={activeTab} onChange={handleChangeTab} variant="scrollable" scrollButtons={false} isBottomLine>
        {tabs?.map(({ label, value }) => (
          <Tab key={value} label={label} value={value} />
        ))}
      </StyledTabs>
    </div>
  );
};

export default NavTabs;
