// third-party
// import { FormattedMessage } from 'react-intl';

import { ADMIN_ROLE } from 'constants/enums';
import { ROUTER_PATH } from 'constants/routerUrl';
import {
  IconClipboardList,
  IconUsers,
  IconCheckupList,
  IconSitemap,
  IconReportAnalytics,
  IconBrowser,
  IconCash,
  IconExchange,
  IconShoppingCart,
  IconBuildingWarehouse,
  IconSettings,
  IconArrowAutofitUp
} from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  // title: <FormattedMessage id="dashboard" />,
  title: '',
  type: 'group',
  children: [
    {
      id: 'business',
      title: 'Business',
      type: 'item',
      url: ROUTER_PATH.DASHBOARD_BUSINESS,
      icon: IconBrowser,
      breadcrumbs: false,
      access: [ADMIN_ROLE.ALL]
    },
    {
      id: 'projects',
      title: 'Projects',
      type: 'item',
      url: ROUTER_PATH.DASHBOARD_PROJECTS,
      icon: IconClipboardList,
      breadcrumbs: false,
      access: [ADMIN_ROLE.ALL]
    },
    {
      id: 'suppliers',
      title: 'Suppliers',
      type: 'item',
      url: ROUTER_PATH.DASHBOARD_SUPPLIERS,
      icon: IconUsers,
      breadcrumbs: false,
      access: [ADMIN_ROLE.ALL]
    },
    {
      id: 'material_requests',
      title: 'Material Requests',
      type: 'item',
      url: ROUTER_PATH.DASHBOARD_RFQs,
      icon: IconCheckupList,
      breadcrumbs: false,
      access: [ADMIN_ROLE.ALL]
    },
    {
      id: 'material_request_items',
      title: 'Material Request Items',
      type: 'item',
      url: ROUTER_PATH.DASHBOARD_MATERIALS,
      icon: IconSitemap,
      breadcrumbs: false,
      access: [ADMIN_ROLE.ALL]
    },
    {
      id: 'offers_collapse',
      title: 'Offers',
      type: 'collapse',
      url: ROUTER_PATH.DASHBOARD_OFFERS,
      icon: IconReportAnalytics,
      breadcrumbs: false,
      access: [ADMIN_ROLE.ALL],
      children: [
        {
          id: 'offers',
          title: 'Offers',
          type: 'item',
          icon: IconCash,
          url: ROUTER_PATH.DASHBOARD_OFFERS,
          breadcrumbs: false,
          access: [ADMIN_ROLE.ALL]
        },
        {
          id: 'mix_bids',
          title: 'Mixed Bids',
          type: 'item',
          icon: IconExchange,
          url: ROUTER_PATH.DASHBOARD_MIXED_BIDS,
          breadcrumbs: false,
          access: [ADMIN_ROLE.ALL]
        }
      ]
    },
    {
      id: 'orders',
      title: 'Orders',
      type: 'item',
      url: ROUTER_PATH.DASHBOARD_ORDERS,
      icon: IconShoppingCart,
      breadcrumbs: false,
      access: [ADMIN_ROLE.ALL]
    },
    {
      id: 'stock',
      title: 'Stock',
      type: 'item',
      url: ROUTER_PATH.DASHBOARD_STORAGES,
      icon: IconBuildingWarehouse,
      breadcrumbs: false,
      access: [ADMIN_ROLE.ALL]
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      url: `${ROUTER_PATH.DASHBOARD_SETTINGS}${ROUTER_PATH.MEMBERS}`,
      icon: IconSettings,
      breadcrumbs: false,
      access: [ADMIN_ROLE.SUPER_ADMIN]
    },
    // GRADER
    {
      id: 'grader',
      title: 'Grader',
      type: 'item',
      url: ROUTER_PATH.GRADER,
      icon: IconArrowAutofitUp,
      breadcrumbs: false,
      access: [ADMIN_ROLE.ALL]
    }
  ]
};

export default dashboard;
