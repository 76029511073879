import { Button } from '@mui/material';

import { ModalLayout } from 'ui-component/modals';
import { useConfirmModal } from 'store/atoms/confirmStore';
import { isEmptyObject } from 'utils/validData';

import s from './s.module.scss';

const ConfirmModal = () => {
  const { state, offConfirmModal } = useConfirmModal((store) => ({
    state: store.state,
    offConfirmModal: store.offConfirmModal
  }));

  const handleCloseModal = () => {
    if (state.onClose) {
      state.onClose();
    }
    offConfirmModal();
  };

  const handleConfirm = () => {
    if (state.onConfirm) {
      state.onConfirm();
    }
    handleCloseModal();
  };

  return (
    <ModalLayout isOpen={!isEmptyObject(state)} onClose={handleCloseModal} maxWidth="sm">
      <div className={s.modal_wrapper}>
        {state.question ? <p className={s.question}>{state.question}</p> : null}
        {state.text ? <p className={s.text}>{state.text}</p> : null}
        <div className={s.btns_wrapper}>
          <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
            {state.closeBtn || 'Cancel'}
          </Button>
          {state.mode === 'info' ? null : (
            <Button variant="contained" color="secondary" onClick={handleConfirm}>
              {state.confirmBtn || 'Confirm'}
            </Button>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};

export default ConfirmModal;
