import { ModalLayout } from 'ui-component/modals';

import OwnerForm from './OwnerForm';

const BusinessOwnerForm = ({ ownersModalState, onClose, setOwner }) => {
  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <ModalLayout isOpen={ownersModalState.isOpen} onClose={handleCloseModal} maxWidth="md">
      <OwnerForm onClose={handleCloseModal} ownersModalState={ownersModalState} setOwner={setOwner} />
    </ModalLayout>
  );
};

export default BusinessOwnerForm;
