import { Tooltip as TooltipMUI, Fade, styled } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const TooltipStyled = styled(({ className, ...props }) => (
  <TooltipMUI TransitionComponent={Fade} classes={{ tooltip: className }} {...props} />
))(({ isNode, customStyle }) => ({
  backgroundColor: isNode ? '#ffffff' : '#616161',
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25)',
  maxWidth: isNode ? 'min-content' : '300px',
  lineHeight: '14px',
  textAlign: 'center',
  color: isNode ? 'black' : '#ffffff',
  ...customStyle
}));

const Tooltip = ({
  title,
  children,
  iconColor = 'disabled',
  fontSize = 'medium',
  placement = 'bottom',
  isNode,
  customStyle
}) => (
  <TooltipStyled title={title} placement={placement} isNode={isNode} customStyle={customStyle}>
    {children || <InfoOutlined fontSize={fontSize} color={iconColor} />}
  </TooltipStyled>
);

export default Tooltip;
