import { useEffect } from 'react';

import { useAuthStore } from 'store/atoms/authStore';
import { ADMIN_ROLE } from 'constants/enums';

const useAccessByRole = (options) => {
  const { allowedRoles, onMountSecure } = options || {};

  // if for some reason a user's role is not found, they are assigned the role with the least access.
  const userRole = useAuthStore((store) => store.user.role || ADMIN_ROLE.SUPER_MANAGER);

  const onHasAccessByRole = (roles) => {
    if (userRole === ADMIN_ROLE.SUPER_ADMIN) {
      return true;
    }
    if (Array.isArray(roles)) {
      return roles.includes(ADMIN_ROLE.ALL) || roles.includes(userRole);
    }
    return roles === ADMIN_ROLE.ALL || userRole === roles;
  };

  const isRole = {
    SUPER_ADMIN: userRole === ADMIN_ROLE.SUPER_ADMIN,
    SUPER_MANAGER: userRole === ADMIN_ROLE.SUPER_MANAGER
  };

  useEffect(() => {
    if (onMountSecure && allowedRoles) {
      if (!onHasAccessByRole(allowedRoles)) {
        onMountSecure();
      }
    }
  }, [userRole]);

  return {
    onHasAccessByRole,
    ADMIN_ROLE,
    isRole
  };
};

export default useAccessByRole;
