import CurrencyInput from 'react-currency-input-field';
import clsx from 'clsx';

// import { Tooltip } from 'components';

import s from './s.module.scss';

const sizeCN = {
  sm: s.sm,
  xs: s.xs
};

const InputCurrency = ({
  value,
  onChange,
  objKey = '',
  placeholder,
  label,
  isDisabled = false,
  error,
  size,
  isRequired,
  // tooltip,
  decimalsLimit = 3,
  allowNegativeValue = false,
  prefix = '$',
  groupSeparator = ',',
  decimalSeparator = '.'
}) => {
  const handleChange = (amount) => {
    if (onChange) {
      onChange(objKey, amount ?? '');
    }
  };

  return (
    <div className={clsx(s.input_wrapper, sizeCN[size])}>
      <p className={s.label}>
        {label}
        {/* {tooltip ? <Tooltip /> : null} */}
      </p>
      <div className={s.input_block}>
        <CurrencyInput
          prefix={prefix}
          placeholder={placeholder}
          value={value}
          decimalsLimit={decimalsLimit}
          onValueChange={handleChange}
          allowNegativeValue={allowNegativeValue}
          disabled={isDisabled}
          groupSeparator={groupSeparator}
          decimalSeparator={decimalSeparator}
          className={s.input}
          required={isRequired}
        />
      </div>
      {error ? <span className={s.error}>{error}</span> : null}
    </div>
  );
};

export default InputCurrency;
