export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const adjustColor = (color, amount) => {
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);

  const factor = 1 + amount / 100;
  r = Math.round(Math.min(255, Math.max(0, r * factor)));
  g = Math.round(Math.min(255, Math.max(0, g * factor)));
  b = Math.round(Math.min(255, Math.max(0, b * factor)));

  const newHex = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`;
  return newHex;
};

export const isHexColor = (str) => /^#([0-9A-F]{3}){1,2}$/i.test(str);

export const avatarGradient = (string) => {
  let color;

  if (isHexColor(string)) {
    color = string;
  } else {
    color = stringToColor(string);
  }

  const topColor = adjustColor(color, 20);
  return `linear-gradient(180deg, ${topColor} 0%, ${color} 100%)`;
};
