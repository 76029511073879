import { DetailsView } from 'ui-component/components/_Details';
import { CategoriesView } from 'ui-component/components/_UI/_Material';

import s from './s.module.scss';

const MaterialDetails = ({ material = {}, children }) => {
  const materialDetails = material?.material || material;

  return (
    <div className={s.details_wrapper}>
      <DetailsView label="Name:" value={materialDetails.name} />
      <DetailsView label="Item #:" value={materialDetails.itemNumber} />
      <DetailsView label="Manufacturer:" value={materialDetails.manufacturer} />
      <DetailsView label="Brand:" value={materialDetails.brand} />
      <DetailsView label="Category:" value={<CategoriesView material={materialDetails} />} size="sm" />
      {material.note ? <DetailsView label="Note:" value={<p className={s.note}>{material.note}</p>} /> : null}
      {children}
    </div>
  );
};

export default MaterialDetails;
