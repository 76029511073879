import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { useSearchParams } from 'hooks';
import { CheckBox, AvatarGroup, NoRowsOverlay } from 'ui-component/components';
import { LoaderBlock } from 'ui-component/components/_Loaders';
import { AddButton } from 'ui-component/components/_Buttons';
import { InviteUserToBusinessModal } from 'ui-component/modals';
import MATERIAL_REQUESTS_API from 'api/materialRequests';
import { useConfirmModal } from 'store/atoms/confirmStore';
import { useLoaderStore } from 'store/atoms/loaderStore';
import { isNullable } from 'utils/validData';
import { capitalizeFirstLetter } from 'utils/stringFormat';
import { getBusinessRootUser } from 'utils/dto';
import { toastError } from 'utils/events';
import { ROUTER_PARAMS } from 'constants/routerUrl';
import { USER_ROLES } from 'constants/enums';

import s from './s.module.scss';

const AddUsersToRFQ = ({ onCloseModal, assignedUsers, onSuccessCallback }) => {
  const setLoader = useLoaderStore((store) => store.setLoader);
  const onConfirmModal = useConfirmModal((store) => store.onConfirmModal);

  const { searchParams } = useSearchParams();

  const [business, setBusiness] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(() => assignedUsers?.map(({ id }) => id) || []);
  const [inviteModalState, setInviteModalState] = useState({});

  const onSelectUsers = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers((currState) => currState.filter((id) => id !== userId));
    } else {
      setSelectedUsers((currState) => [...currState, userId]);
    }
  };

  const handleSaveUsers = () => {
    onConfirmModal({
      question: 'Add Users to RFQ',
      text: 'Are you sure you want to add selected users to this RFQ?',
      onConfirm: () => {
        setLoader.on();
        MATERIAL_REQUESTS_API.addUsersToRfq(searchParams.get(ROUTER_PARAMS.RFQ_ID), selectedUsers)
          .then(() => {
            if (onSuccessCallback) {
              onSuccessCallback();
            }
            onCloseModal();
          })
          .catch(toastError)
          .finally(setLoader.off);
      }
    });
  };

  const handleGetBusinessInfo = (options) => {
    const { callback } = options || {};
    MATERIAL_REQUESTS_API.getBusinessByRFQId(searchParams.get(ROUTER_PARAMS.RFQ_ID))
      .then((resp) => {
        if (callback) {
          callback();
        }
        const businessTeam = resp.users
          .filter(({ role }) => role !== USER_ROLES.TECH)
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        setBusiness({ ...resp, businessTeam });
      })
      .catch(({ message }) => {
        toastError({ message });
        onCloseModal();
      });
  };

  useEffect(() => {
    handleGetBusinessInfo();
  }, []);

  if (isNullable(business)) {
    return <LoaderBlock />;
  }
  return (
    <div className={s.modal}>
      {business.businessTeam.length ? (
        <div className={s.users_wrapper}>
          {business.businessTeam.map((user) => {
            const userLabel = user.firstName ? `${user.firstName} ${user.lastName}` : user.email;
            return (
              <CheckBox
                key={user.id}
                isChecked={selectedUsers.includes(user.id)}
                onChange={() => onSelectUsers(user.id)}
                label={
                  <div className={s.user_block}>
                    <AvatarGroup users={[{ name: userLabel }]} />
                    <span>{`${userLabel} (${capitalizeFirstLetter(user.role)})`}</span>
                  </div>
                }
              />
            );
          })}
        </div>
      ) : (
        <NoRowsOverlay message="There are no added users in the business" height="100px" />
      )}
      <div className={s.control}>
        <AddButton
          onClick={() => {
            const rootUser = getBusinessRootUser(business.users);
            setInviteModalState({ id: rootUser.id });
          }}
        >
          Invite new user
        </AddButton>
        <Button type="submit" variant="contained" color="secondary" onClick={handleSaveUsers}>
          Save
        </Button>
      </div>
      <InviteUserToBusinessModal
        isOpen={!isEmpty(inviteModalState)}
        onClose={() => setInviteModalState({})}
        state={inviteModalState}
        onSuccessCallback={handleGetBusinessInfo}
      />
    </div>
  );
};

export default AddUsersToRFQ;
