import { create } from 'zustand';

// title, text, onClose, content, closeBnt
const initialState = {
  state: {}
};

export const useAlertStore = create((set) => ({
  ...initialState,
  onAlertModal: (state) => set({ state }),
  offAlertModal: () => set({ state: {} })
}));
