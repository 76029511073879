import PhoneInput from 'react-phone-input-2';
import clsx from 'clsx';

import 'react-phone-input-2/lib/material.css';
import s from './style.module.scss';

const sizeCN = {
  sm: s.sm,
  xs: s.xs
};

const InputPhoneNumber = ({
  value,
  label,
  onChange,
  placeholder,
  error,
  size,
  inputKey,
  defaultCountry = 'us',
  onlyCountries = ['us', 'ca']
}) => {
  const handleChange = (phone) => {
    onChange?.(inputKey, `+${phone}`);
  };

  return (
    <div className={clsx(s.phone_number_wrapper, sizeCN[size])}>
      {label ? (
        <p className={s.label}>
          {label}
          {/* {tooltip ? <Tooltip title={tooltip} /> : null} */}
        </p>
      ) : null}
      <PhoneInput
        country={defaultCountry}
        placeholder={placeholder}
        countryCodeEditable={false}
        disableDropdown
        onlyCountries={onlyCountries}
        value={value}
        onChange={handleChange}
        containerClass={s.containerClass}
        inputClass={s.inputClass}
        buttonClass={s.buttonClass}
      />
      {error ? <span className={s.error}>{error}</span> : null}
    </div>
  );
};

export default InputPhoneNumber;
