import { useRef, useEffect } from 'react';
import clsx from 'clsx';

import { useBoolean, useIsMount } from 'hooks';
import { onScrollIntoView } from 'utils/pageHelper';
import { ReactComponent as ArrowNavIcon } from 'assets/images/arrowNav.svg';

import s from './s.module.scss';

const Accordion = ({ label, children, mb = 10, initialIsOpen = false, action }) => {
  const isMount = useIsMount();
  const contentRef = useRef(null);

  const [isOpen, setIsOpen] = useBoolean(initialIsOpen);

  useEffect(() => {
    if (!isMount && isOpen && contentRef.current) {
      onScrollIntoView(contentRef, { settings: { block: 'center' } });
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen.set(initialIsOpen);
  }, [initialIsOpen]);

  return (
    <div style={{ marginBottom: `${mb}px` }}>
      {label ? (
        <div className={s.controls}>
          <button type="button" className={clsx(s.acc_btn, isOpen && s.acc_btn_open)} onClick={setIsOpen.toggle}>
            {label} <ArrowNavIcon />
          </button>
          {action || null}
        </div>
      ) : null}
      <div className={clsx(s.acc, isOpen && s.acc_open)}>
        {isOpen ? (
          <div ref={contentRef} className={s.content}>
            {children}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Accordion;
