import { useRef } from 'react';

const useScrollToRef = (settings) => {
  const { offset = -70 } = settings || {};
  const ref = useRef(null);

  const scrollToRef = () => {
    setTimeout(() => {
      if (ref.current) {
        window.scrollTo({
          top: ref.current.offsetTop + offset,
          behavior: 'smooth'
        });
      }
    }, 100);
  };

  return [ref, scrollToRef];
};

export default useScrollToRef;
