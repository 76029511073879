import qs from 'query-string';
import { AUTH_INSTANCE } from './api';

const OATFI_API = {
  // http://3.234.153.217:8080/swagger-ui/#/oat-fi-controller/getOatFiBusinessByAdminUsingGET
  getBusiness: (businessId) => {
    const query = qs.stringify({ businessId });
    return AUTH_INSTANCE.get(`/oatfi/admin/business?${query}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/oat-fi-controller/createOatFiBusinessForAdminUsingPOST
  createBusiness: ({ businessId }) => {
    const query = qs.stringify({ businessId });
    return AUTH_INSTANCE.post(`/oatfi/admin/business?${query}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/oat-fi-controller/updateOatFiBusinessInfoUsingPUT_1
  updateBusiness: (businessId) => {
    const query = qs.stringify({ businessId });
    return AUTH_INSTANCE.put(`/oatfi/admin/business?${query}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/oat-fi-controller/updateOatFiBusinessForAdminManualUsingPUT
  updateBusinessManual: (data) => AUTH_INSTANCE.put('/oatfi/admin/business/manual', data),
  // http://3.234.153.217:8080/swagger-ui/#/oat-fi-controller/requestFullUnderwritingAdminUsingGET
  callFullUnderwriting: (businessId) => {
    const query = qs.stringify({ businessId });
    return AUTH_INSTANCE.get(`/oatfi/admin/underwrite?${query}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/oat-fi-controller/checkFullUnderwritingStatusUsingGET
  checkFullUnderwritingStatus: (businessId) => {
    const query = qs.stringify({ businessId });
    return AUTH_INSTANCE.get(`/oatfi/admin/underwrite/status?${query}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/oat-fi-controller/provideInvoiceDataForMaterialsOfferByAdminUsingPOST
  provideInvoiceHistoryByBid: ({ offerId, oatFiInvoiceDueDate, businessId }) => {
    const query = qs.stringify({ oatFiInvoiceDueDate, businessId }, { skipEmptyString: true, skipNull: true });
    return AUTH_INSTANCE.post(`/oatfi/admin/invoices/materials-offer/${offerId}?${query}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/oat-fi-controller/provideInvoiceDataForMixedBidUsingPOST_1
  provideInvoiceHistoryByMixedBid: ({ mixedBidId, oatFiInvoiceDueDate, businessId }) => {
    const query = qs.stringify({ oatFiInvoiceDueDate, businessId }, { skipEmptyString: true, skipNull: true });
    return AUTH_INSTANCE.post(`/oatfi/admin/invoices/mixed-bid/${mixedBidId}?${query}`);
  },
  // http://3.234.153.217:8080/swagger-ui/#/oat-fi-controller/getLoanInfoForMaterialsOfferUsingGET
  getLoanInfoBid: (materialsOfferId) => AUTH_INSTANCE.get(`/oatfi/loan/materials-offer/${materialsOfferId}`),
  // http://3.234.153.217:8080/swagger-ui/#/oat-fi-controller/getLoanInfoForMixedBidUsingGET
  getLoanInfoMixedBid: (mixedBidId) => AUTH_INSTANCE.get(`/oatfi/loan/mixed-bid/${mixedBidId}`)
};

export default OATFI_API;
