import s from './s.module.scss';

const DetailsView = ({ label, value, emptyValue = '-' }) => (
  <div className={s.details}>
    <div className={s.label}>{label}</div>
    <div className={s.value}>{value || emptyValue}</div>
  </div>
);

export default DetailsView;
