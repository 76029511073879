import clsx from 'clsx';
import Percent from '@mui/icons-material/Percent';

import { Tooltip } from 'ui-component/components';

import s from './s.module.scss';

const sizeCN = {
  sm: s.sm,
  xs: s.xs
};

const InputPercent = ({ value, onChange, placeholder = 'Enter percentage', label, size = 'md', error, tooltip }) => {
  const handleChange = (event) => {
    let { value } = event.target;

    if (value === '') {
      onChange('');
      return;
    }

    value = value.replace(',', '.');

    const regex = /^(?!0\.00)(?:\d{1,2}(?:\.\d{1,2})?|\d{1}(?:\.\d{1,2})?|100(?:\.0{1,2})?|\d{1,2}\.)$/;

    if (!regex.test(value)) {
      return;
    }

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={clsx(s.input_wrapper, sizeCN[size])}>
      {label ? (
        <p className={s.label}>
          {label}
          {tooltip ? <Tooltip fontSize="small" title={tooltip} /> : null}
        </p>
      ) : null}
      <div className={s.input_block}>
        <span className={s.end_adornment}>
          <Percent fontSize="small" />
        </span>
        <input type="text" value={value} onChange={handleChange} className={s.input} placeholder={placeholder} />
      </div>
      {error ? <span className={s.error}>{error}</span> : null}
    </div>
  );
};

export default InputPercent;
