import axios from 'axios';

import { useAuthStore } from 'store/atoms/authStore';
import { MESSAGES } from 'constants/enums';

const onError = (error) => {
  if (error?.response?.status === 401) {
    useAuthStore.getState().signOut();
    window.location.reload();
  }
  return Promise.reject(error?.response?.data || { message: MESSAGES.ERROR });
};

const DEFAULT_INSTANCE_SETTINGS = {
  baseURL: process.env.REACT_APP_API,
  headers: { 'Content-Type': 'application/json' }
};

const AUTH_INSTANCE = axios.create(DEFAULT_INSTANCE_SETTINGS);
const DEF_INSTANCE = axios.create(DEFAULT_INSTANCE_SETTINGS);

AUTH_INSTANCE.interceptors.request.use((req) => {
  req.headers.Authorization = `Bearer ${useAuthStore.getState().token}`;
  return req;
});

AUTH_INSTANCE.interceptors.response.use(({ data }) => data, onError);
DEF_INSTANCE.interceptors.response.use(({ data }) => data, onError);

export { DEF_INSTANCE, AUTH_INSTANCE };
