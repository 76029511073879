import qs from 'query-string';
import { AUTH_INSTANCE } from './api';

const STRIPE_API = {
  getPayDirectlyInvoice: (offerId) => AUTH_INSTANCE.post(`/stripe/pay-directly/${offerId}`),
  getPayDirectlyInvoiceMixedBid: (mixedBidId) => AUTH_INSTANCE.post(`/stripe/pay-directly/mixed-bid/${mixedBidId}`),
  getNetTermsInvoiceBid: (offerId) => AUTH_INSTANCE.post(`/stripe/net-terms/materials-offer/${offerId}`),
  getNetTermsInvoiceMixedBid: (mixedBidId) => AUTH_INSTANCE.post(`/stripe/net-terms/mixed-bid/${mixedBidId}`),
  // http://3.234.153.217:8080/swagger-ui/#/business-controller/getAllTariffsUsingGET
  // getAllPlans: () => AUTH_INSTANCE.get('/businesses/tariffs/enum'),
  // http://3.234.153.217:8080/swagger-ui/#/business-controller/updateStockTariffUsingPATCH_1
  updateBusinessTariff: (businessId, data, params) => {
    const query = qs.stringify(params, { skipEmptyString: true, skipNull: true });
    return AUTH_INSTANCE.patch(`/businesses/admin/${businessId}/tariff?${query}`, data);
  }
};

export default STRIPE_API;
