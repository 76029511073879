/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-autofocus */
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';

import { useBoolean, useClickOutside } from 'hooks';
import { ONE_FIELD_REQUIRED } from 'constants/validatorForms';

import s from './s.module.scss';

const EditableInlineText = ({ children, value, onUpdate }) => {
  const wrapperRef = useRef();

  const { register, handleSubmit, setValue } = useForm({
    mode: 'onSubmit',
    defaultValues: { value: value || '' },
    resolver: yupResolver(ONE_FIELD_REQUIRED({ fieldName: 'value' }))
  });

  const [isEditing, setIsEditing] = useBoolean(false);

  const handleOpenEditMode = () => {
    if (!isEditing) {
      setIsEditing.on();
    }
  };

  const handleCancel = () => {
    setIsEditing.off();
    setValue('value', value);
  };

  useClickOutside([wrapperRef], handleCancel);

  const handleOnSubmit = (data) => {
    if (onUpdate) {
      onUpdate(data, {
        onSuccess: handleCancel
      });
    }
  };

  const onError = (data) => {
    toast.error(data.value.message);
  };

  return (
    <div ref={wrapperRef} role="button" tabIndex="0" onClick={handleOpenEditMode} className={s.edit_wrapper}>
      {isEditing ? (
        <form onSubmit={handleSubmit(handleOnSubmit, onError)} className={s.form}>
          <input autoFocus type="text" {...register('value')} className={s.input} />
          <div className={s.actions}>
            <button type="submit">
              <Check fontSize="small" />
            </button>
            <button type="button" onClick={handleCancel}>
              <Close fontSize="small" />
            </button>
          </div>
        </form>
      ) : (
        children
      )}
    </div>
  );
};

export default EditableInlineText;
