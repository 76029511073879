import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, IconButton } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import DeleteForever from '@mui/icons-material/DeleteForever';

import { Input, InputCurrency, NoRowsOverlay } from 'ui-component/components';
import { AddButton, SettingsButton } from 'ui-component/components/_Buttons';
import { useConfirmModal } from 'store/atoms/confirmStore';
import { ADDITIONAL_INVOICE_ITEMS_SCHEME } from 'constants/validatorForms';

import s from './s.module.scss';

const AdditionalInvoiceItems = ({ state = [], onSuccessCallback, onCloseModal, options }) => {
  const { clearAllMode = true } = options || {};
  const onConfirmModal = useConfirmModal((store) => store.onConfirmModal);

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      additionalInvoiceItems: state.length ? state : [{ name: '', cost: '' }]
    },
    resolver: yupResolver(ADDITIONAL_INVOICE_ITEMS_SCHEME)
  });

  const {
    fields: additionalInvoiceFields,
    remove: additionalInvoiceFieldRemove,
    append: additionalInvoiceFieldAppend
  } = useFieldArray({
    control,
    name: 'additionalInvoiceItems',
    keyName: 'invoiceId'
  });

  const isAdditionalInvoiceFields = additionalInvoiceFields.length;

  const handleRemoveFields = (index) => {
    onConfirmModal({
      question: 'Deletion of Field',
      text: 'Are you sure you want to delete this field?',
      confirmBtn: 'Delete Field',
      onConfirm: () => {
        additionalInvoiceFieldRemove(index);
      }
    });
  };

  const handleClearAllFields = () => {
    onConfirmModal({
      question: 'Confirm Deletion of All Fields',
      text: 'Are you sure you want to delete all fields?',
      confirmBtn: 'Clear',
      onConfirm: () => {
        setValue('additionalInvoiceItems', [{ name: '', cost: '' }]);
        onSuccessCallback([]);
        onCloseModal();
      }
    });
  };

  const handleOnSubmit = ({ additionalInvoiceItems }) => {
    onSuccessCallback(additionalInvoiceItems);
    onCloseModal();
  };

  return (
    <form className={s.form} onSubmit={handleSubmit(handleOnSubmit)}>
      <h4 className="fl_sb_c_w mb_15 mt_15">
        Additional Payment Fields{' '}
        <AddButton size="sm" onClick={() => additionalInvoiceFieldAppend({ name: '', cost: '' })}>
          Add Field
        </AddButton>
      </h4>
      <div className={s.fields}>
        {isAdditionalInvoiceFields ? (
          additionalInvoiceFields.map((item, index) => (
            <div key={item.invoiceId} className={s.field}>
              <Input
                size="sm"
                label="Field Name"
                placeholder="Enter payment field name"
                register={register(`additionalInvoiceItems.${index}.name`)}
                error={errors?.additionalInvoiceItems?.[index]?.name?.message}
              />
              <Controller
                control={control}
                name={`additionalInvoiceItems.${index}.cost`}
                render={({ field }) => (
                  <InputCurrency
                    size="sm"
                    label="Amount"
                    placeholder="Enter amount"
                    decimalsLimit={3}
                    value={field.value}
                    onChange={(_, value) => field.onChange(value)}
                    error={errors?.additionalInvoiceItems?.[index]?.cost?.message}
                  />
                )}
              />
              <IconButton
                className="mt_15"
                size="small"
                onClick={() => handleRemoveFields(index)}
                disabled={item.isDisabled}
              >
                <DeleteForever />
              </IconButton>
            </div>
          ))
        ) : (
          <NoRowsOverlay height="50px" message="Additional payment fields are not set up" />
        )}
      </div>
      <div className={s.actions}>
        {clearAllMode && isAdditionalInvoiceFields ? (
          <SettingsButton icon={<Clear />} onClick={handleClearAllFields}>
            Clear Additional Fields
          </SettingsButton>
        ) : null}
        <Button type="submit" variant="contained" color="secondary">
          {isAdditionalInvoiceFields ? 'Add Additional Payment Fields' : 'Set to empty'}
        </Button>
      </div>
    </form>
  );
};

export default AdditionalInvoiceItems;
