// ==============================|| LOGO SVG ||============================== //

const Logo = () => (
  <svg width="70" height="41" viewBox="0 0 80 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.756 0H47.4428V39.2587H41.756V0Z" fill="black" />
    <path
      d="M59.0962 10.9032L66.626 31.3037L73.9451 10.9032H80.0005L67.2569 44.1811C66.625 45.7956 66.0458 46.6953 65.5193 47.5377C64.9927 48.4151 64.3433 49.0645 63.571 49.4857C62.7987 49.9419 61.7632 50.1701 60.4643 50.1701H57.7377V45.4407C58.826 45.4407 60.8295 45.5299 61.5148 45.2622C62.2001 44.9945 62.3748 44.4814 62.8312 43.3231L64.4644 39.2909L53.0409 10.9032H59.0962Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.40342 10.9033H0.224987C-0.0382673 10.9033 -0.0870852 11.278 0.167382 11.3455L1.30236 11.6463C4.68372 12.5426 7.1301 15.4769 7.40342 18.9643V50.174H12.8076V34.377H12.816V30.2446C11.769 28.6474 11.16 26.7371 11.16 24.6846C11.16 19.0764 15.7064 14.5301 21.3146 14.5301C21.4306 14.5301 21.5462 14.532 21.6613 14.5359V10.9033H12.6927H7.40342ZM14.5883 32.2922C16.3795 33.8771 18.7347 34.8392 21.3146 34.8392C26.9228 34.8392 31.4692 30.2929 31.4692 24.6846C31.4692 19.766 27.972 15.6641 23.3285 14.7298V10.9206C25.0596 10.9998 26.6446 11.4833 28.0835 12.1073C29.7699 12.8221 31.2072 13.8189 32.3954 15.098C33.6219 16.377 34.5609 17.8817 35.2125 19.6121C35.864 21.3049 36.1898 23.1294 36.1898 25.0856C36.1898 27.0417 35.9959 28.885 35.3444 30.6154C34.6928 32.3458 33.7729 33.8506 32.5848 35.1296C31.3966 36.4086 29.9593 37.4243 28.2729 38.1767C26.5865 38.8914 24.6892 39.2488 22.5812 39.2488C20.6265 39.2488 18.7868 38.835 17.062 38.0074C16.1314 37.5608 15.3069 37.0158 14.5883 36.3721V32.2922Z"
      fill="black"
    />
  </svg>
);

export default Logo;
