import { ModalLayout } from 'ui-component/modals';
import AddUsersToRFQ from './AddUsersToRFQ';

const AddUsersToRFQModal = ({ assignedUsers, onSuccessCallback, ...modalProps }) => {
  return (
    <ModalLayout {...modalProps} maxWidth="sm">
      <AddUsersToRFQ assignedUsers={assignedUsers} onSuccessCallback={onSuccessCallback} />
    </ModalLayout>
  );
};

export default AddUsersToRFQModal;
